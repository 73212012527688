import React, { ReactNode } from "react";
import { Field, useField } from "react-final-form";

// Define props interface
interface FieldInputWebProps {
  label: string;
  name: string;
  type?: string;
  placeholder?: string;
  component?: string;
  children?: ReactNode;
  onSelect?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  requiredField?: boolean;
  disabledFiled?: boolean;
  maxLength?: number;
}

const FieldInputWeb: React.FC<FieldInputWebProps> = ({
  label,
  name,
  type = "text",
  placeholder,
  component,
  children,
  onSelect,
  onChange,
  requiredField = false,
  disabledFiled = false,
  maxLength,
}) => {
  const { input, select, meta } = useField(name);

  const allowDigits =
    (numberOfDigits: number) => (value: string | undefined) => {
      if (!value) return value;
      return value.replace(/[^\d]/g, "").slice(0, numberOfDigits);
    };

  return (
    <div className="w-full">
      <label className="text-lg my-2 font-light flex">
        {label}
        {requiredField ? (
          <span className="text-red-500 ml-1">*</span>
        ) : null}
      </label>
      <div className="flex flex-col relative pb-5">
        {component === "counter" ? (
          <div className="relative">
            <input
              {...input}
              className={`w-full h-[40px] px-4 my-2 text-center text-sm bg-white rounded-[30px] focus:outline-[#040a3d] border-[1px] 
                  ${
                    meta.error && meta.touched
                      ? "border-[1px] border-rose-500"
                      : ""
                  }`}
              type="number"
              placeholder={placeholder}
              min={0}
            />
          </div>
        ) : component === "textarea" ? (
          <textarea
            {...input}
            className={`w-full h-[80px] px-3 py-2 my-2 text-sm bg-white rounded-[15px] focus:outline-[#040a3d] border-[1px] 
              ${
                meta.error && meta.touched ? "border-[1px] border-rose-500" : ""
              }`}
            placeholder={placeholder}
            disabled={disabledFiled}
          />
        ) : component === "select" ? (
          <select
            {...select}
            className={`w-full h-[40px] px-4 my-2 text-[13px] bg-white rounded-[30px] focus:outline-[#040a3d] border-[1px] 
                ${
                  meta.error && meta.touched
                    ? "border-[1px] border-rose-500"
                    : ""
                }`}
            onSelect={onSelect}
            onChange={onChange}
            disabled={disabledFiled}
          >
            {children}
          </select>
        ) : component === "dateTime" ? (
          <div className="relative">
            <input
              {...input}
              className={`w-full h-[40px] px-4 my-2 text-center text-sm bg-white rounded-[30px] focus:outline-[#040a3d] border-[1px] 
                ${
                  meta.error && meta.touched
                    ? "border-[1px] border-rose-500"
                    : ""
                }`}
              type="datetime-local"
              placeholder={placeholder}
              min={0}
            />
          </div>
        ) : maxLength ? (
          <Field
            className={`w-full h-[40px] px-4 my-2 text-sm bg-white rounded-[30px] focus:outline-[#040a3d] border-[1px] 
                ${
                  meta.error && meta.touched
                    ? "border-[1px] border-rose-500"
                    : ""
                }`}
            name={name}
            parse={allowDigits(maxLength)}
            component="input"
          />
        ) : (
          <input
            {...input}
            className={`w-full h-[40px] px-4 my-2 text-md bg-white rounded-[30px] focus:outline-[#040a3d] border-[1px] 
                ${
                  meta.error && meta.touched
                    ? "border-[1px] border-rose-500"
                    : ""
                }`}
            type={type}
            placeholder={placeholder}
            disabled={disabledFiled}
          />
        )}
        {meta.error && meta.touched && (
          <span className="text-xs text-red-500 pt-1 absolute bottom-1">
            {meta.error}
          </span>
        )}
      </div>
    </div>
  );
};

export default FieldInputWeb;
