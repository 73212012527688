import { Link } from "react-router-dom";
import LayoutWeb from "../../components/LayoutWeb";
import CustomerTable from "../../components/table/CustomerTable";
import "../../index.css";

const Home = () => {
  return (
    <>
      <LayoutWeb>
        <div className="flex justify-between">
          <div>
            <h1 className="text-3xl font-bold text-blue-600">
              ข้อมูลลูกค้าทั้งหมด
            </h1>
          </div>
          <div>
            <Link to="/create-customer">
              <button className="bg-[#13C296] hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                เพิ่มลูกค้าใหม่
              </button>
            </Link>
          </div>
        </div>
        <CustomerTable />
      </LayoutWeb>
    </>
  );
};

export default Home;
