import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getStorage, removeStorage } from "../../utils/storage";

const initialState = {
  isAuth: false as boolean,
  token: "" as string,
  refreshToken: "" as string,
  loading: false as boolean,
  userInfo: {} as any,
};

interface UserInfo {
  name: string;
}

interface SetUserInfoPayload {
  accessToken: string;
  user: UserInfo;
}

interface SetUserProfilePayload {
  user: UserInfo;
}

interface SetTokenPayload {
  accessToken: string;
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state) => {
      state.isAuth = true;
    },
    logout: (state) => {
      state.isAuth = false;
      state.token = "";
      state.refreshToken = "";
      state.userInfo = {};
      removeStorage("token");
      removeStorage("refreshToken");
      removeStorage("userInfo");
    },
    getAuth: (state) => {
      const token = getStorage("token");
      const refreshToken = getStorage("refreshToken");
      const userInfo = getStorage("userInfo");
      if (token && userInfo) {
        state.token = token ? token : "";
        state.refreshToken = refreshToken ? refreshToken : "";
        state.userInfo = userInfo;
      }
    },
    setUserInfo: (state, action: PayloadAction<SetUserInfoPayload>) => {
      const { accessToken, user } = action.payload;
      state.token = accessToken;
      state.userInfo = user;
    },
    setUserProfile: (state, action: PayloadAction<SetUserProfilePayload>) => {
      const { user } = action.payload;
      state.userInfo = user;
    },
    setToken: (state, action: PayloadAction<SetTokenPayload>) => {
      const { accessToken } = action.payload;
      state.token = accessToken;
    },
  },
});

export const { login, logout, getAuth, setUserInfo, setUserProfile, setToken } =
  authSlice.actions;

export default authSlice.reducer;
