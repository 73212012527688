import React, { ReactNode } from 'react'

interface BgWhiteFormProps {
  children: ReactNode;
}
const BgWhiteForm: React.FC<BgWhiteFormProps> = ({children}) => {
  return (
    <div className="w-full bg-[#ffffff] shadow-lg shadow-gray-400 rounded-[20px] p-[15px] md:px-[50px] md:py-[40px] mt-8 h-max">
      {children}
    </div>
  )
}

export default BgWhiteForm