import React, { useEffect, useState } from "react";
import Logo from "../assets/Rectangle245.svg";
import { Form } from "react-final-form";
import FieldInputWeb from "../components/FieldInputWeb";
import BgWhiteForm from "../components/BgWhiteForm";
import FooterRegister from "../components/footer/FooterRegister";
import newCustomerRegisterServices from "../services/newCustomerApi";
import Swal from "sweetalert2";

interface Province {
  id: number;
  name_th: string;
}
interface Amphuren {
  id: number;
  name_th: string;
}
interface Tambon {
  id: number;
  name_th: string;
}
interface ProvincesSelected {
  id: string;
}
interface CategoryTypes {
  id: number;
  name: string;
}
interface BusinessType {
  id: number;
  category_type_id: number;
  name: string;
}

interface MainAddress {
  is_natural_person: boolean;
  is_head_office: boolean;
  branch_name: string;
  company_name: string;
  tax_id: string;
  postal_code: string;
  province_id: number;
  amphure_id: number;
  tambon_id: number;
  address: string;
  phone: string;
}

interface AnotherAddress extends MainAddress {
  // If the structure is the same, you can reuse MainAddress or define additional fields if necessary
}
interface FormDataType {
  category_type_id: number;
  business_type_id: number;
  business_name: string;
  signer_full_name: string;
  signer_position: string;
  signer_phone: string;
  signer_email: string;
  signer_line_id: string;
  coordinator_full_name: string;
  coordinator_position: string;
  coordinator_phone: string;
  map_url: string;
  is_use_another_address: boolean;
  main_address: MainAddress;
  another_address?: AnotherAddress; // This is optional
}

interface RegisterFormValues {
  address: string;
  addressDoc: string;
  branch: boolean;
  branchDoc: boolean;
  branchName: string;
  branchNameDoc: string;
  businessSubType: string;
  categoryType: string;
  companyName: string;
  companyNameDoc: string;
  companyNameLabel: string;
  coordinator: string;
  coordinatorPhone: string;
  coordinatorPosition: string;
  District: string;
  DistrictDoc: string;
  email: string;
  legalEntityRegistrationNumber: string;
  legalEntityRegistrationNumberDoc: string;
  lineID: string;
  personType: boolean;
  personTypeDoc: boolean;
  province: string;
  provinceDoc: string;
  signContract: string;
  signerPhone: string;
  signerPhoneDoc: string;
  signerPosition: string;
  tambon: string;
  tambonDoc: string;
  zipCode: string;
  zipCodeDoc: string;
}

const NewCustomerRegister: React.FC = () => {
  const [showLegalEntitySelection, setShowLegalEntitySelection] =
    useState(false);
    const [showinputBranch, setShowinputBranch] = useState(false)
    const [showinputBranchDoc, setShowinputBranchDoc] = useState(false)
  const [
    showUseAnotherAddressForDocument,
    setshowUseAnotherAddressForDocument,
  ] = useState(false);
  const [
    showLegalEntitySelectionForDocument,
    setshowLegalEntitySelectionForDocument,
  ] = useState(false);

  const [provinces, setProvinces] = useState<Province[]>([]);
  const [provincesDoc, setProvincesDoc] = useState<Province[]>([]);
  const [provincesSelected, setProvincesSelected] = useState<ProvincesSelected | null>(null);
  const [provincesSelectedDoc, setProvincesSelectedDoc] = useState<ProvincesSelected | null>(null);
  const [amphures, setAmphure] = useState<Amphuren[]>([]);
  const [amphuresDoc, setAmphureDoc] = useState<Amphuren[]>([]);
  const [tambons, setTambon] = useState<Tambon[]>([]);
  const [tambonsDoc, setTambonDoc] = useState<Tambon[]>([]);
  const [selectedProvinceId, setSelectedProvinceId] = useState<string | null>(null);
  const [selectedProvinceIdDoc, setSelectedProvinceIdDoc] = useState<string | null>(null);
  const [personType, setPersonType] = useState<boolean>();
  const [branch, setBranch] = useState<boolean>();
  const [branchDoc, setBranchDoc] = useState<boolean>();
  const [personTypeDoc, setPersonTypeDoc] = useState<boolean>();
  const [categoryTypes, setCategoryTypes] = useState<CategoryTypes[]>([]);
  const [businessTypes, setBusinessTypes] = useState<BusinessType[]>([]);
  const [formDoc, setFormDoc] = useState<Boolean>(false)

  const fetchProvinces = async () => {
    try {
      const response = await newCustomerRegisterServices.getProvices();
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        console.log("else setProvinces");
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetch provinces", err);
    }
  };
  const fetchAmphure = async (value: string) => {
    try {
      const response = await newCustomerRegisterServices.getAmphure(value);
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetch fetchAmphure", err);
    }
  };

  const fetchCategoryTypes = async () => {
    try {
      const response = await newCustomerRegisterServices.getCategoryTypes();
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetch provinces", err);
    }
  };
  const fetchBusinessType = async (value: string) => {
    try {
      const response = await newCustomerRegisterServices.getBusinessTypes(
        value
      );
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetchBusinessType", err);
    }
  };

  const handleSelectProvinces = async (value: string) => {
    console.log("handleSelectProvinces value:", value);
    let newListAmphure = await fetchAmphure(value);
    setAmphure(newListAmphure);
    setProvincesSelected({ id: value });
  };

  const handleSelectProvincesDoc = async (value: string) => {
    let newListAmphure = await fetchAmphure(value);
    setAmphureDoc(newListAmphure);
    setProvincesSelectedDoc({ id: value });
  };

  const handleSelectDistrict = async (value: string) => {
    console.log("handleSelectDistrict value:", value);
    if (!provincesSelected) {
      return;
    }
    try {
      const response = await newCustomerRegisterServices.getTambon(
        provincesSelected.id,
        value
      );
      console.log("API handleSelectDistrict:", response);
      if (Array.isArray(response.data)) {
        setTambon(response.data);
      } else {
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetch handleSelectDistrict", err);
    }
  };
  const handleSelectDistrictDoc = async (value: string) => {
    console.log("handleSelectDistrict value:", value);
    if (!provincesSelectedDoc) {
      return;
    }
    try {
      const response = await newCustomerRegisterServices.getTambon(
        provincesSelectedDoc.id,
        value
      );
      if (Array.isArray(response.data)) {
        setTambonDoc(response.data);
      } else {
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetch handleSelectDistrictDoc", err);
    }
  };

  const handleInputZipCode = async (valueZipCodeInput: string) => {
    console.log("handleInputZipCode :", valueZipCodeInput);

    let newProvinces = await fetchProvinces();
    if (valueZipCodeInput) {
      // try {
      const response = await newCustomerRegisterServices.postcodeAddresses(
        valueZipCodeInput
      );
      if (Array.isArray(response.data) && response.data.length > 0) {
        console.log(response.data);
        const listIdProvinceByZipCode: Set<number> = new Set();
        const listIdDritrictByZipCode: Set<number> = new Set();
        const listIdTombonByZipCode: Set<number> = new Set();

        // Collect province IDs from the response data
        response.data.forEach((element: any) => {
          if (element.amphure && element.amphure.province) {
            listIdProvinceByZipCode.add(element.amphure.province.id);
            listIdDritrictByZipCode.add(element.amphure.id);
          }
          listIdTombonByZipCode.add(element.id);
        });

        // Filter the provinces list to only include those with IDs in listIdProvinceByZipCode
        const filteredProvinces = await newProvinces.filter(
          (province: { id: number }) => listIdProvinceByZipCode.has(province.id)
        );

        await setProvinces(filteredProvinces);
        return;
      }
    }
    await setProvinces(newProvinces);
  };
  const handleInputZipCodeDoc = async (valueZipCodeInput: string) => {
    let newProvinces = await fetchProvinces();
    if (valueZipCodeInput) {
      // try {
      const response = await newCustomerRegisterServices.postcodeAddresses(
        valueZipCodeInput
      );
      if (Array.isArray(response.data) && response.data.length > 0) {
        console.log(response.data);
        const listIdProvinceByZipCode: Set<number> = new Set();
        const listIdDritrictByZipCode: Set<number> = new Set();
        const listIdTombonByZipCode: Set<number> = new Set();

        // Collect province IDs from the response data
        response.data.forEach((element: any) => {
          if (element.amphure && element.amphure.province) {
            listIdProvinceByZipCode.add(element.amphure.province.id);
            listIdDritrictByZipCode.add(element.amphure.id);
          }
          listIdTombonByZipCode.add(element.id);
        });

        // Filter the provinces list to only include those with IDs in listIdProvinceByZipCode
        const filteredProvinces = await newProvinces.filter(
          (province: { id: number }) => listIdProvinceByZipCode.has(province.id)
        );

        await setProvincesDoc(filteredProvinces);
        return;
      }
    }
    await setProvincesDoc(newProvinces);
  };

  const OnSelectPersonTyprPersonal = (showDisplay: boolean) => {
    setShowLegalEntitySelection(!showDisplay);
    setPersonType(showDisplay);
  };
  const OnSelectUseAnotherAddressForDocument = (showDisplay: boolean) => {
    setshowUseAnotherAddressForDocument(showDisplay);
    if (showDisplay) {
      setUpProvicesDoc();
    }
  };
  const OnSelectShowLegalEntitySelectionForDocument = (
    showDisplay: boolean
  ) => {
    setshowLegalEntitySelectionForDocument(showDisplay);
    setBranchDoc(showDisplay);
    setPersonTypeDoc(showDisplay);
  };

  const setUpProvices = async () => {
    let provinces = await fetchProvinces();
    setProvinces(provinces);
  };
  const setUpProvicesDoc = async () => {
    let provincesDoc = await fetchProvinces();
    setProvincesDoc(provincesDoc);
  };
  const setUpCategoryTypes = async () => {
    let category = await fetchCategoryTypes();
    setCategoryTypes(category);
  };
  const handleSelectCateegory = async (value: string) => {
    let newListBusinessType = await fetchBusinessType(value);
    setBusinessTypes(newListBusinessType);
  };

  const initialValues = {
    address: "",
    addressDoc: "",
    branch: false,
    branchDoc: false,
    branchName: "",
    branchNameDoc: "",
    businessSubType: "",
    categoryType: "",
    companyName: "",
    companyNameDoc: "",
    companyNameLabel: "",
    coordinator: "",
    coordinatorPhone: "",
    coordinatorPosition: "",
    District: "",
    DistrictDoc: "",
    email: "",
    legalEntityRegistrationNumber: "",
    legalEntityRegistrationNumberDoc: "",
    lineID: "",
    personType: false,
    personTypeDoc: false,
    province: "",
    provinceDoc: "",
    signContract: "",
    signerPhone: "",
    signerPhoneDoc: "",
    signerPosition: "",
    tambon: "",
    tambonDoc: "",
    zipCode: "",
    zipCodeDoc: "",
  };

  const handleSubmitRegisterNewCustomer = async (
    values: RegisterFormValues
  ) => {
    console.log(values);
    
    Swal.fire({
      position: "top",
      title: "ยืนยันการลงทะเบียน?",
      text: "หากท่านยืนยันข้อมูลแล้วจะไม่สามารถกลับไปแก้ไขได้!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "ยืนยัน",
      cancelButtonColor: "#d33",
      cancelButtonText: "ยกเลิก",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const payload = {
          ...values,
          personType: personType ?? values.personType,
          branch: branch ?? values.branch,
          personTypeDoc: personTypeDoc ?? values.personTypeDoc,
          branchDoc: branchDoc ?? values.branchDoc,
        };
        console.log("payload :", payload);
        let formData: FormDataType = {
          category_type_id: Number(payload.categoryType),
          business_type_id: Number(payload.businessSubType),
          business_name: payload.companyNameLabel,
          signer_full_name: payload.signContract,
          signer_position: payload.signerPosition,
          signer_phone: payload.signerPhone,
          signer_email: payload.email,
          signer_line_id: payload.lineID,
          coordinator_full_name: payload.coordinator,
          coordinator_position: payload.coordinatorPosition,
          coordinator_phone: payload.coordinatorPhone,
          map_url: "",
          is_use_another_address: Boolean(payload.personTypeDoc),
          main_address: {
            is_natural_person: payload.personType, // assuming true means natural person
            is_head_office: payload.branch, // assuming branch false means head office
            branch_name: values.branchName,
            company_name: payload.companyName,
            tax_id: payload.legalEntityRegistrationNumber,
            postal_code: payload.zipCode,
            province_id: Number(payload.province),
            amphure_id: Number(payload.District),
            tambon_id: Number(payload.tambon),
            address: payload.address,
            phone: payload.coordinatorPhone, // using coordinatorPhone for phone field, adjust as needed
          },
        };
        if (payload.personTypeDoc) {
          const anotherAddress = {
            is_natural_person: values.personTypeDoc === true, // Assuming you have a similar flag for person type
            is_head_office: values.branchDoc === false, // Assuming branchDoc false means head office
            branch_name: values.branchNameDoc, // branch_name comes from branchNameDoc
            company_name: values.companyNameDoc,
            tax_id: values.legalEntityRegistrationNumberDoc,
            postal_code: values.zipCodeDoc,
            province_id: Number(values.provinceDoc),
            amphure_id: Number(values.DistrictDoc),
            tambon_id: Number(values.tambonDoc),
            address: values.addressDoc,
            phone: values.signerPhoneDoc, // Assuming the phone field is the same for both addresses, adjust if needed
          };
          formData.another_address = anotherAddress;
        }
        try {
          const addCustomerResponse =
            await newCustomerRegisterServices.addNewCustomer(formData);
          console.log("addCustomerResponse :", addCustomerResponse);

          if (addCustomerResponse.status === 200) {
            Swal.fire({
              position: "top",
              title: "ลงทะเบียนสำเร็จแล้ว",
              html: "ขอบคุณสำหรับการลงทะเบียน<br>เจ้าหน้าที่ของเราจะติดต่อไปหาคุณโดยเร็วที่สุด",
              icon: "success",
              confirmButtonColor: "#3758F9",
              confirmButtonText: "เสร็จสิ้น",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "https://www.chokumnuai.com/";
              }
            });
          } else {
            Swal.fire({
              position: "top",
              icon: "error",
              title: "ลงทะเบียนไม่สำเร็จ",
              text: "โปรดลงทะเบียนใหม่อีกครั้งภายหลัง",
            });
          }
        } catch (error) {
          console.error("Error occurred during customer registration:", error);
        }
      }
    });
  };

  const validate = (values: any) => {
    const errors: any = {};

    // Validate Business Info
    if (!values.personType) {
      errors.personType = "กรุณากรอกข้อมูล";
    }
    if (!values.categoryType) {
      errors.categoryType = "กรุณากรอกข้อมูล";
    }
    if (!values.businessSubType) {
      errors.businessSubType = "กรุณากรอกข้อมูล";
    }
    if (!values.companyName) {
      errors.companyName = "กรุณากรอกข้อมูล";
    }
    if (!values.companyNameLabel) {
      errors.companyNameLabel = "กรุณากรอกข้อมูล";
    }
    if (!values.signerPosition) {
      errors.signerPosition = "กรุณากรอกข้อมูล";
    }
    if (!values.signerPhone) {
      errors.signerPhone = "กรุณากรอกข้อมูล";
    } else if (!/^\d{10}$/.test(values.signerPhone)) {
      errors.signerPhone = "กรุณากรอกข้อมูล";
    }
    if (!values.email) {
      errors.email = "กรุณากรอกข้อมูล";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(values.email)) {
      errors.email = "กรอก email ไม่ถูกต้อง";
    }

    // Validate Coordinator Info
    if (!values.coordinator) {
      errors.coordinator = "กรุณากรอกข้อมูล";
    }
    if (!values.coordinatorPhone) {
      errors.coordinatorPhone = "กรุณากรอกข้อมูล";
    } else if (!/^\d{10}$/.test(values.coordinatorPhone)) {
      errors.coordinatorPhone = "กรอกได้ 10 ตัว";
    }

    // Validate Address Info
    if (!values.address) {
      errors.address = "กรุณากรอกข้อมูล";
    }
    if (!values.zipCode) {
      errors.zipCode = "กรุณากรอกข้อมูล";
    }
    if (!values.province) {
      errors.province = "กรุณากรอกข้อมูล";
    }
    if (!values.District) {
      errors.District = "กรุณากรอกข้อมูล";
    }
    if (!values.tambon) {
      errors.tambon = "กรุณากรอกข้อมูล";
    }

    // Validate another address if personTypeDoc is true
    if (formDoc) {
      if (!values.personTypeDoc) {
        errors.personTypeDoc = "กรุณากรอกข้อมูล";
      }
      if (!values.companyNameDoc) {
        errors.companyNameDoc = "กรุณากรอกข้อมูล";
      }
      if (!values.addressDoc) {
        errors.addressDoc = "กรุณากรอกข้อมูล";
      }
      if (!values.zipCodeDoc) {
        errors.zipCodeDoc = "กรุณากรอกข้อมูล";
      }
      if (!values.provinceDoc) {
        errors.provinceDoc = "กรุณากรอกข้อมูล";
      }
      if (!values.DistrictDoc) {
        errors.DistrictDoc = "กรุณากรอกข้อมูล";
      }
      if (!values.tambonDoc) {
        errors.tambonDoc = "กรุณากรอกข้อมูล";
      }
    }

    return errors;
  };

  useEffect(() => {
    setUpProvices();
    setUpCategoryTypes();
  }, []);

  return (
    <>
      <div className="max-w-full mx-auto font-kanit">
        <div className="max-w-full h-9 lg:h-14 bg-gradient-to-r from-[#ffffff] via-[#8AC2F5] via-[#54a8f7] to-[#1C00C6]">
          <div className="max-w-screen-lg mx-auto">
            <div className="flex flex-col">
              <div className="flex justify-between h-3 md:h-8 lg:h-14 mt-1 lg:mt-0">
                <div>
                  <img
                    src={Logo}
                    alt="icon-notice"
                    className="w-14 md:w-16 lg:w-28 mx-auto absolute top-0 left-[4%]"
                  />
                </div>
                <div className="absolute top-0 right-0 md:right-7 top-1 md:top-3 text-lg md:text-lg lg:text-2xl text-[#FFF] mt-auto mb-auto  mr-4 md:mr-5 lg:mr-0">
                  บริษัท นิวโชคอำนวย เชียงใหม่ จำกัด
                </div>
              </div>

              <div className="mt-10 mb-10 mx-auto">
                <div className="block md:hidden text-2xl mx-auto text-center">
                  <div>กรอกข้อมูลเพื่อลงทะเบียนสมัคร</div>
                  <div>ใช้บริการเก็บขนและกำจัดมูลฝอยติดเชื้อ</div>
                </div>
                <div className="hidden md:block text-2xl lg:text-3xl">
                  กรอกข้อมูลเพื่อลงทะเบียนสมัครใช้บริการเก็บขนและกำจัดมูลฝอยติดเชื้อ
                </div>
              </div>
              {/* React Final Form */}
              <Form
                onSubmit={handleSubmitRegisterNewCustomer}
                initialValues={initialValues}
                validate={validate}
                render={({ handleSubmit, values, submitting, pristine }) => (
                  <>
                    <form onSubmit={handleSubmit}>
                      <BgWhiteForm>
                        <div className="flex flex-col mx-3 md:mx-0">
                          <div className="mb-4 -ml-4 text-2xl">
                            ข้อมูลใช้สำหรับการทำสัญญา
                          </div>
                          <div className="text-lg my-2 font-light">
                            ประเภทผู้ติดต่อ
                          </div>
                          <div className="grid grid-cols-2 md:grid-cols-5">
                            <div className="">
                              <FieldInputWeb
                                name="personType"
                                component="radio"
                                type="radio"
                                requiredField
                                value="บุคคลธรรมดา"
                                onChange={() =>
                                  OnSelectPersonTyprPersonal(true)
                                }
                              >
                                บุคคลธรรมดา
                              </FieldInputWeb>
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="personType"
                                component="radio"
                                type="radio"
                                onChange={() =>
                                  OnSelectPersonTyprPersonal(false)
                                }
                                requiredField
                                value="นิติบุคคล"
                                className=""
                              >
                                นิติบุคคล
                              </FieldInputWeb>
                            </div>
                          </div>

                          <div
                            className={`grid grid-cols-2 md:grid-cols-5 ${
                              showLegalEntitySelection ? "" : "hidden"
                            }`}
                          >
                            <div className="">
                              <FieldInputWeb
                                name="branch"
                                component="radio"
                                type="radio"
                                requiredField
                                onChange={() => {setBranch(false);setShowinputBranch(false)}}
                              >
                                สำนักงานใหญ่
                              </FieldInputWeb>
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="branch"
                                component="radio"
                                type="radio"
                                className=""
                                requiredField
                                onChange={() => {setBranch(true);setShowinputBranch(true)}}
                              >
                                สาขา
                              </FieldInputWeb>
                            </div>
                            <div className={`col-span-5 ${showinputBranch? "":"hidden"} `}>
                              <FieldInputWeb
                                name="branchName"
                                type="string"
                                placeholder="ชื่อสาขา"
                                className="my-0 md:w-[70%]"
                              />
                            </div>
                          </div>
                          <div className="grid-project grid grid-cols-1 lg:grid-cols-2 gap-x-3">
                            <div className="">
                              <FieldInputWeb
                                name="companyName"
                                type="string"
                                label={
                                  personType
                                    ? "ชื่อ - นามสกุล"
                                    : "ห้างหุ้นส่วน/บริษัท"
                                }
                                requiredField
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="legalEntityRegistrationNumber"
                                type="string"
                                label={
                                  personType
                                    ? "เลขประจำตัวประชาชน"
                                    : "เลขทะเบียนนิติบุคคล"
                                }
                                maxLength={13}
                                requiredField
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="categoryType"
                                component="select"
                                label="หมวดหมู่สถานพยาบาล"
                                requiredField={true}
                                onChange={(e) =>
                                  handleSelectCateegory(e.target.value)
                                }
                              >
                                {/* These will be the options for the select input */}
                                <option
                                  value=""
                                  selected
                                  disabled
                                  className="text-gray-400"
                                >
                                  เลือกหมวดหมู่สถานพยาบาล
                                </option>
                                {categoryTypes.map((categoryType) => (
                                  <option
                                    key={categoryType.id}
                                    value={categoryType.id}
                                  >
                                    {categoryType.name}
                                  </option>
                                ))}
                              </FieldInputWeb>
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="businessSubType"
                                component="select"
                                label="ประเภทสถานพยาบาล"
                                requiredField={true}
                              >
                                {/* These will be the options for the select input */}
                                <option value="" disabled>
                                  {"เลือกประเภทกิจการ (เลือกหมวดหมู่ก่อน)"}
                                </option>
                                {businessTypes.map((businessType) => (
                                  <option
                                    key={businessType.id}
                                    value={businessType.id}
                                  >
                                    {businessType.name}
                                  </option>
                                ))}
                              </FieldInputWeb>
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="companyNameLabel"
                                type="string"
                                label="ชื่อสถานที่"
                                placeholder="ตัวอย่าง โรงพยาบาลนิวโชคอำนวย"
                                requiredField
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="zipCode"
                                type="number"
                                label="รหัสไปรษณีย์"
                                placeholder="50000"
                                requiredField
                                maxLength={5}
                                onBlur={(e) =>
                                  handleInputZipCode(e.target.value)
                                }
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="province"
                                component="select"
                                label="จังหวัด"
                                requiredField={true}
                                onChange={(e) =>
                                  handleSelectProvinces(e.target.value)
                                }
                                value={selectedProvinceId || ""}
                              >
                                <option value="" selected>
                                  เลือกจังหวัด
                                </option>
                                {provinces.map((province) => (
                                  <option key={province.id} value={province.id}>
                                    {province.name_th}
                                  </option>
                                ))}
                              </FieldInputWeb>
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="District"
                                component="select"
                                label="อําเภอ"
                                requiredField={true}
                                onChange={(e) =>
                                  handleSelectDistrict(e.target.value)
                                }
                              >
                                {/* These will be the options for the select input */}
                                <option value="" disabled selected>
                                  {"เลือกอำเภอ (โปรดเลือกจังหวัดก่อน)"}
                                </option>
                                {amphures.map((amphure) => (
                                  <option key={amphure.id} value={amphure.id}>
                                    {amphure.name_th}
                                  </option>
                                ))}
                              </FieldInputWeb>
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="tambon"
                                component="select"
                                label="ตำบล"
                                requiredField={true}
                              >
                                {/* These will be the options for the select input */}
                                <option value="" disabled selected>
                                  {"เลือกตำบล (โปรดเลือกอำเภอก่อน)"}
                                </option>
                                {tambons.map((tambon) => (
                                  <option key={tambon.id} value={tambon.id}>
                                    {tambon.name_th}
                                  </option>
                                ))}
                              </FieldInputWeb>
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="address"
                                type="string"
                                label="ที่อยู่"
                                component="textarea"
                                requiredField={true}
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="signContract"
                                type="string"
                                label="ผู้มีอำนาจรับผิดชอบ/ลงนามสัญญา"
                                requiredField
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="signerPosition"
                                type="string"
                                label="ตำแหน่งผู้มีอำนาจรับผิดชอบ/ลงนามสัญญา"
                                placeholder=""
                                requiredField
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="signerPhone"
                                type="number"
                                label="เบอร์โทรศัพท์มือถือ"
                                maxLength={10}
                                requiredField
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="email"
                                type="email"
                                label="อีเมล"
                                requiredField
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="lineID"
                                type="string"
                                label="LINE ID"
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="coordinator"
                                type="string"
                                label="ผู้ประสานงาน"
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="coordinatorPosition"
                                type="string"
                                label="ตำแหน่งผู้ประสานงาน"
                                placeholder=""
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="coordinatorPhone"
                                type="number"
                                label="เบอร์โทรศัพท์มือถือ"
                                maxLength={10}
                              />
                            </div>
                          </div>
                        </div>
                      </BgWhiteForm>
                      <BgWhiteForm>
                        <div className="flex flex-col mx-3 md:mx-0">
                          <div className="mb-4 -ml-4 text-2xl">
                            ข้อมูลใช้สำหรับการออกเอกสารใบแจ้งหนี้/ใบเสร็จรับเงิน
                          </div>
                          <div className="grid grid-cols-2 md:grid-cols-5">
                            <div className="">
                              <FieldInputWeb
                                name="FormDoc"
                                component="radio"
                                type="radio"
                                requiredField
                                value={false}
                                onChange={() =>
                                  {OnSelectUseAnotherAddressForDocument(false);setFormDoc(false)}
                                }
                                // checked={initialValues.FormDoc === false}
                              >
                                ใช้ข้อมูลข้างต้น
                              </FieldInputWeb>
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="FormDoc"
                                component="radio"
                                type="radio"
                                requiredField
                                value={true}
                                onChange={() =>
                                  {OnSelectUseAnotherAddressForDocument(true);setFormDoc(true)}
                                }
                                className=""
                              >
                                ใช้ข้อมูลอื่น
                              </FieldInputWeb>
                            </div>
                          </div>
                          <div
                            className={`${
                              showUseAnotherAddressForDocument ? "" : "hidden"
                            }`}
                          >
                            <div className="text-lg font-light mb-3">
                              ประเภทผู้ติดต่อ
                            </div>
                            <div className="grid grid-cols-2 md:grid-cols-5">
                              <div className="">
                                <FieldInputWeb
                                  name="personTypeDoc"
                                  component="radio"
                                  type="radio"
                                  requiredField
                                  onChange={() =>
                                    OnSelectShowLegalEntitySelectionForDocument(
                                      false
                                    )
                                  }
                                >
                                  บุคคลธรรมดา
                                </FieldInputWeb>
                              </div>
                              <div className="">
                                <FieldInputWeb
                                  name="personTypeDoc"
                                  component="radio"
                                  type="radio"
                                  onChange={() =>
                                    OnSelectShowLegalEntitySelectionForDocument(
                                      true
                                    )
                                  }
                                  requiredField
                                  value="นิติบุคคล"
                                  className="ml-2 md:ml-0"
                                >
                                  นิติบุคคล
                                </FieldInputWeb>
                              </div>
                            </div>
                            <div
                              className={`grid grid-cols-2 md:grid-cols-5 ${
                                showLegalEntitySelectionForDocument
                                  ? ""
                                  : "hidden"
                              }`}
                            >
                              <div className="">
                                <FieldInputWeb
                                  name="branchDoc"
                                  component="radio"
                                  type="radio"
                                  onChange={() => {setBranchDoc(false);setShowinputBranchDoc(false)}}
                                >
                                  สำนักงานใหญ่
                                </FieldInputWeb>
                              </div>
                              <div className="">
                                <FieldInputWeb
                                  name="branchDoc"
                                  component="radio"
                                  type="radio"
                                  className="ml-2 md:ml-0"
                                  onChange={() => {setBranchDoc(true);setShowinputBranchDoc(true)}}
                                >
                                  สาขา
                                </FieldInputWeb>
                              </div>
                              <div className={`col-span-5 ${showinputBranchDoc? "":"hidden"} `}>
                                <FieldInputWeb
                                  name="branchNameDoc"
                                  type="string"
                                  placeholder="ชื่อสาขา"
                                  className="my-0 md:w-[70%]"
                                />
                              </div>
                            </div>
                            <div className="grid-project grid grid-cols-1 lg:grid-cols-2 gap-x-3">
                              <div className="">
                                <FieldInputWeb
                                  name="companyNameDoc"
                                  type="string"
                                  label={
                                    !personTypeDoc
                                      ? "ชื่อ - นามสกุล"
                                      : "ห้างหุ้นส่วน/บริษัท"
                                  }
                                  requiredField
                                />
                              </div>
                              <div className="">
                                <FieldInputWeb
                                  name="legalEntityRegistrationNumberDoc"
                                  type="string"
                                  label={
                                    !personTypeDoc
                                      ? "เลขประจำตัวประชาชน"
                                      : "เลขทะเบียนนิติบุคคล"
                                  }
                                  placeholder="1234567890123"
                                  maxLength={13}
                                  requiredField
                                />
                              </div>
                              <div className="">
                                <FieldInputWeb
                                  name="zipCodeDoc"
                                  type="number"
                                  label="รหัสไปรษณีย์"
                                  placeholder="50000"
                                  requiredField
                                  maxLength={5}
                                  onBlur={(e) =>
                                    handleInputZipCodeDoc(e.target.value)
                                  }
                                />
                              </div>
                              <div className="">
                                <FieldInputWeb
                                  name="provinceDoc"
                                  component="select"
                                  label="จังหวัด"
                                  onChange={(e) =>
                                    handleSelectProvincesDoc(e.target.value)
                                  }
                                  value={selectedProvinceIdDoc || ""}
                                  requiredField
                                >
                                  {/* These will be the options for the select input */}
                                  <option value="" disabled selected>
                                    เลือกจังหวัด
                                  </option>
                                  {provincesDoc.map((provinceDoc) => (
                                    <option
                                      key={provinceDoc.id}
                                      value={provinceDoc.id}
                                    >
                                      {provinceDoc.name_th}
                                    </option>
                                  ))}
                                </FieldInputWeb>
                              </div>
                              <div className="">
                                <FieldInputWeb
                                  name="DistrictDoc"
                                  component="select"
                                  label="อําเภอ"
                                  requiredField
                                  onChange={(e) =>
                                    handleSelectDistrictDoc(e.target.value)
                                  }
                                >
                                  {/* These will be the options for the select input */}
                                  <option value="" disabled selected>
                                    {"เลือกอำเภอ (โปรดเลือกจังหวัดก่อน)"}
                                  </option>
                                  {amphuresDoc.map((amphure) => (
                                    <option key={amphure.id} value={amphure.id}>
                                      {amphure.name_th}
                                    </option>
                                  ))}
                                </FieldInputWeb>
                              </div>
                              <div className="">
                                <FieldInputWeb
                                  name="tambonDoc"
                                  component="select"
                                  label="ตำบล"
                                  requiredField
                                >
                                  <option value="" disabled selected>
                                    {"เลือกตำบล (โปรดเลือกอำเภอก่อน)"}
                                  </option>
                                  {tambonsDoc.map((tambon) => (
                                    <option key={tambon.id} value={tambon.id}>
                                      {tambon.name_th}
                                    </option>
                                  ))}
                                </FieldInputWeb>
                              </div>
                              <div className="">
                                <FieldInputWeb
                                  name="addressDoc"
                                  type="string"
                                  label="ที่อยู่"
                                  component="textarea"
                                  requiredField={true}
                                />
                              </div>
                              <div className="">
                                <FieldInputWeb
                                  name="signerPhoneDoc"
                                  type="number"
                                  label="เบอร์โทรศัพท์มือถือ"
                                  requiredField
                                  maxLength={10}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </BgWhiteForm>

                      <div className="mt-8 mb-16 text-center">
                        <button
                          type="submit"
                          className="bg-[#3758F9] text-white p-2 rounded w-[20%]"
                          disabled={submitting || pristine}
                        >
                          ลงทะเบียน
                        </button>
                      </div>
                    </form>
                  </>
                )}
              />
            </div>
          </div>
          <div className="w-full bg-[#1C00C6]">
            <FooterRegister />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewCustomerRegister;
