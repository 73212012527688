// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-dropdown-header {
  position: absolute;
  right: 0;
  top: 45px;
  width: 175px;
  margin: 0;
  opacity: 1;
  transition: top 0.3s ease, opacity 0.3s ease;
}
`, "",{"version":3,"sources":["webpack://./src/components/navbar/Navbar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,YAAY;EACZ,SAAS;EACT,UAAU;EACV,4CAA4C;AAC9C","sourcesContent":[".menu-dropdown-header {\n  position: absolute;\n  right: 0;\n  top: 45px;\n  width: 175px;\n  margin: 0;\n  opacity: 1;\n  transition: top 0.3s ease, opacity 0.3s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
