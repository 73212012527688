import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

interface FormValues {
  name: string;
  email: string;
  password: string;
  gender: string;
  terms: boolean;
  favoriteColor: string;
  file: File | null;
}

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name is too short!')
    .max(50, 'Name is too long!')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .required('Password is required'),
  gender: Yup.string()
    .oneOf(['male', 'female', 'other'], 'Invalid gender selection')
    .required('Gender is required'),
  terms: Yup.boolean()
    .oneOf([true], 'You must accept the terms and conditions'),
  favoriteColor: Yup.string().required('Please select your favorite color'),
  file: Yup.mixed()
    .required('A file is required')
    .test('fileSize', 'File too large', (value) => {
      return value && (value as File).size <= 1024 * 1024; // Max size: 1MB
    })
    .test('fileFormat', 'Unsupported Format', (value) => {
      return value && ['image/jpg', 'image/jpeg', 'image/png'].includes((value as File).type);
    }),
});

const MyForm: React.FC = () => {
  const initialValues: FormValues = {
    name: '',
    email: '',
    password: '',
    gender: '',
    terms: false,
    favoriteColor: '',
    file: null,
  };

  const handleSubmit = (values: FormValues) => {
    console.log('Form data', values);
  };

  return (
    <div>
      <h1>Signup Form</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={SignupSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form>
            {/* Text Input */}
            <div>
              <label htmlFor="name">Name</label>
              <Field type="text" name="name" />
              <ErrorMessage name="name" component="div" />
            </div>

            {/* Email Input */}
            <div>
              <label htmlFor="email">Email</label>
              <Field type="email" name="email" />
              <ErrorMessage name="email" component="div" />
            </div>

            {/* Password Input */}
            <div>
              <label htmlFor="password">Password</label>
              <Field type="password" name="password" />
              <ErrorMessage name="password" component="div" />
            </div>

            {/* Radio Input */}
            <div>
              <label>Gender</label>
              <div>
                <Field type="radio" name="gender" value="male" />
                Male
              </div>
              <div>
                <Field type="radio" name="gender" value="female" />
                Female
              </div>
              <div>
                <Field type="radio" name="gender" value="other" />
                Other
              </div>
              <ErrorMessage name="gender" component="div" />
            </div>

            {/* Checkbox Input */}
            <div>
              <label>
                <Field type="checkbox" name="terms" />
                Accept terms and conditions
              </label>
              <ErrorMessage name="terms" component="div" />
            </div>

            {/* Select Dropdown */}
            <div>
              <label htmlFor="favoriteColor">Favorite Color</label>
              <Field as="select" name="favoriteColor">
                <option value="">Select a color</option>
                <option value="red">Red</option>
                <option value="blue">Blue</option>
                <option value="green">Green</option>
              </Field>
              <ErrorMessage name="favoriteColor" component="div" />
            </div>

            {/* File Upload */}
            <div>
              <label htmlFor="file">Upload your profile picture</label>
              <input
                id="file"
                name="file"
                type="file"
                onChange={(event) => {
                  const file = event.currentTarget.files ? event.currentTarget.files[0] : null;
                  setFieldValue('file', file);
                }}
              />
              <ErrorMessage name="file" component="div" />
            </div>

            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MyForm;
