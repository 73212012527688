import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import imgCart from "../../assets/icon/cart.png";
import imgChart from "../../assets/icon/chart.png";
import imgCoin from "../../assets/icon/coin.png";
import imgGarbageTruck from "../../assets/icon/GarbageTruck.png";
import imgGroup from "../../assets/icon/Group.png";
import imgHouse from "../../assets/icon/house.png";
import imgPersonalData from "../../assets/icon/personal-data.png";
import imgSetting from "../../assets/icon/Settings.png";
import imgWrite from "../../assets/icon/write.png";

import logoNewChok from "../../assets/logo/logo-new-chok.png";

import DropdownMenu from "../dropdown/DroupDownMenu";

const Sidebar: React.FC<{ isSubMenuOpen: boolean }> = ({ isSubMenuOpen }) => {
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <aside
        className={` text-white flex flex-col ${
          isSubMenuOpen ? "w-16" : "w-64"
        }`}
      >
        {/* Brand Section */}
        <div className="flex items-center space-x-4 p-4">
          {isSubMenuOpen ? (
            <div className="h-24"></div>
          ) : (
            <img src={logoNewChok} alt="Logo" className="w-full h-24" />
          )}
        </div>
        {/* Navigation Links */}
        <nav className="">
          <Link
            to="/"
            className={`flex  items-center py-2.5 px-4 hover:bg-blue-600 ${
              isActive("/") ? "bg-white/20" : ""
            }`}
          >
            <img src={imgHouse} className="w-6 h-7 mr-2" alt="" />
            {isSubMenuOpen ? "" : "หน้าหลัก"}
          </Link>
          <DropdownMenu
            title="ข้อมูลลูกค้า"
            submenus={[
              { name: "สมัครลูกค้า", url: "/register" },
              { name: "ลูกค้าลงทะเบียนใหม่", url: "/new-customers" },
              { name: "ลูกค้าทั้งหมด", url: "/customers" },
            ]}
            isSubMenuOpen={isSubMenuOpen}
          />
          <Link to="/" className="flex py-2.5 px-4 hover:bg-blue-600 my-2">
            <img src={imgCoin} className="w-6 h-7 mr-2" alt="" />
            {isSubMenuOpen ? "" : "เอกสารขาย"}
          </Link>
          <Link to="/" className="flex py-2.5 px-4 hover:bg-blue-600 my-2">
            <img src={imgWrite} className="w-6 h-7 mr-2" alt="" />
            {isSubMenuOpen ? "" : "สัญญา"}
          </Link>
          <Link to="/" className="flex py-2.5 px-4 hover:bg-blue-600 my-2">
            <img src={imgGarbageTruck} className="w-6 h-7 mr-2" alt="" />
            {isSubMenuOpen ? "" : "การจัดการมูลฝอย"}
          </Link>
          <Link to="/" className="flex py-2.5 px-4 hover:bg-blue-600 my-2">
            <img src={imgCart} className="w-6 h-7 mr-2" alt="" />
            {isSubMenuOpen ? "" : "เอกสารซื้อ"}
          </Link>
          <Link to="/" className="flex py-2.5 px-4 hover:bg-blue-600 my-2">
            <img src={imgGroup} className="w-6 h-7 mr-2" alt="" />
            {isSubMenuOpen ? "" : "ค่าใช้จ่าย"}
          </Link>
          <Link to="/" className="flex py-2.5 px-4 hover:bg-blue-600 my-2">
            <img src={imgChart} className="w-6 h-7 mr-2" alt="" />
            {isSubMenuOpen ? "" : "รายงาน"}
          </Link>
          <div className="m-2 mx-4 my-4">
            <div className="border-b-2 border-white"></div>
          </div>
          <Link to="/" className="flex py-2.5 px-4 hover:bg-blue-600 my-2">
            <img src={imgSetting} className="w-7 h-6 mr-2" alt="" />
            {isSubMenuOpen ? "" : "การตั้งค่า"}
          </Link>
        </nav>
      </aside>
    </div>
  );
};

export default Sidebar;
