import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NewCostomerRegister from "../pages/NewCostomerRegister";
import Home from "../pages/Home";
import LoginPage from "../pages/Login";
import PrivateRoute from "./privateRoute";
import { useDispatch } from "react-redux";
import { getAuth } from "../stores/slices/auth";

const Web = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAuth());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<NewCostomerRegister />} />
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Web;
