import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LayoutWeb from "../../components/LayoutWeb";
import newCustomerRegisterServices from "../../services/newCustomerApi";
import BgWhiteForm from "../../components/BgWhiteForm";
import FieldInputWeb from "../../components/FieldInputWeb";
import { Form } from "react-final-form";
import LoaderPage from "../../components/LoaderPage";

interface Province {
  id: number;
  name_th: string;
}
interface Amphuren {
  id: number;
  name_th: string;
}
interface Tambon {
  id: number;
  name_th: string;
}
interface ProvincesSelected {
  id: string;
}
interface CategoryTypes {
  id: number;
  name: string;
}
interface BusinessType {
  id: number;
  category_type_id: number;
  name: string;
}

interface MainAddress {
  id: number;
  is_natural_person: boolean;
  is_head_office: boolean;
  branch_name: string;
  company_name: string;
  tax_id: string;
  postal_code: string;
  province_id: number;
  amphure_id: number;
  tambon_id: number;
  address: string;
  phone: string;
}

interface AnotherAddress extends MainAddress {
  // If the structure is the same, you can reuse MainAddress or define additional fields if necessary
}
interface FormDataType {
  category_type_id: number;
  business_type_id: number;
  business_name: string;
  signer_full_name: string;
  signer_position: string;
  signer_phone: string;
  signer_email: string;
  signer_line_id: string;
  coordinator_full_name: string;
  coordinator_position: string;
  coordinator_phone: string;
  map_url: string;
  is_use_another_address: boolean;
  main_address: MainAddress;
  another_address?: AnotherAddress; // This is optional
}

interface RegisterFormValues {
  address: string;
  addressDoc: string;
  branch: boolean;
  branchDoc: boolean;
  branchName: string;
  branchNameDoc: string;
  businessSubType: string;
  categoryType: string;
  companyName: string;
  companyNameDoc: string;
  companyNameLabel: string;
  coordinator: string;
  coordinatorPhone: string;
  coordinatorPosition: string;
  District: string;
  DistrictDoc: string;
  email: string;
  legalEntityRegistrationNumber: string;
  legalEntityRegistrationNumberDoc: string;
  lineID: string;
  personType: boolean;
  personTypeDoc: boolean;
  province: string;
  provinceDoc: string;
  signContract: string;
  signerPhone: string;
  signerPhoneDoc: string;
  signerPosition: string;
  tambon: string;
  tambonDoc: string;
  zipCode: string;
  zipCodeDoc: string;
}

interface Address {
  id: number;
  customer_id: number;
  is_natural_person: boolean;
  is_head_office: boolean;
  branch_name: string;
  company_name: string;
  tax_id: string;
  postal_code: string;
  province_id: number;
  amphure_id: number;
  tambon_id: number;
  address: string;
  phone: string;
  is_default_address: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface BusinessType {
  id: number;
  category_type_id: number;
  name: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface CategoryType {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface CustomerData {
  id: number;
  category_type_id: number;
  category_type: CategoryType;
  business_type_id: number;
  business_type: BusinessType;
  business_name: string;
  signer_full_name: string;
  signer_position: string;
  signer_phone: string;
  signer_email: string;
  signer_line_id: string;
  coordinator_full_name: string;
  coordinator_position: string;
  coordinator_phone: string;
  is_use_another_address: boolean;
  status: number;
  is_new_customer: boolean | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  addresses: Address[];
}

const NewCustomerDetail: React.FC = () => {
  // Access the dynamic parameter from the URL
  const { id } = useParams<{ id: string | undefined}>();
  const [newCustomer, setNewCustomer] = useState<CustomerData | null>(null);
  const [loadingPage, setLoadingPage] = useState(true);
  const [showLegalEntitySelection, setShowLegalEntitySelection] =
    useState(false);
  const [
    showUseAnotherAddressForDocument,
    setshowUseAnotherAddressForDocument,
  ] = useState(false);
  const [
    showLegalEntitySelectionForDocument,
    setshowLegalEntitySelectionForDocument,
  ] = useState(false);

  const [provinces, setProvinces] = useState<Province[]>([]);
  const [provincesDoc, setProvincesDoc] = useState<Province[]>([]);
  const [provincesSelected, setProvincesSelected] =
    useState<ProvincesSelected | null>(null);
  const [provincesSelectedDoc, setProvincesSelectedDoc] =
    useState<ProvincesSelected | null>(null);
  const [amphures, setAmphure] = useState<Amphuren[]>([]);
  const [amphuresDoc, setAmphureDoc] = useState<Amphuren[]>([]);
  const [tambons, setTambon] = useState<Tambon[]>([]);
  const [tambonsDoc, setTambonDoc] = useState<Tambon[]>([]);
  const [selectedProvinceId, setSelectedProvinceId] = useState<string | null>(
    null
  );
  const [selectedProvinceIdDoc, setSelectedProvinceIdDoc] = useState<
    string | null
  >(null);
  const [personType, setPersonType] = useState<boolean>();
  const [branch, setBranch] = useState<boolean>();
  const [branchDoc, setBranchDoc] = useState<boolean>();
  const [personTypeDoc, setPersonTypeDoc] = useState<boolean>();
  const [categoryTypes, setCategoryTypes] = useState<CategoryTypes[]>([]);
  const [businessTypes, setBusinessTypes] = useState<BusinessType[]>([]);
  
  const initialValues = {
    address: newCustomer?.addresses[0].address|| null,
    addressDoc: newCustomer?.addresses[1]?.address || null,
    branch: newCustomer?.addresses[0].branch_name ? true : false,
    branchDoc: newCustomer?.addresses[1]?.branch_name ? true : false,
    branchName: newCustomer?.addresses[0].branch_name || null,
    branchNameDoc: newCustomer?.addresses[1]?.branch_name|| null,
    businessSubType: newCustomer?.business_type_id|| null,
    categoryType: newCustomer?.category_type_id || null,
    companyName: newCustomer?.addresses[0].company_name || null ,
    companyNameDoc: newCustomer?.addresses[1]?.company_name || null,
    companyNameLabel: newCustomer?.business_name|| null,
    coordinator: newCustomer?.coordinator_full_name|| null,
    coordinatorPhone: newCustomer?.coordinator_phone|| null,
    coordinatorPosition: newCustomer?.coordinator_position|| null,
    District: newCustomer?.addresses[0].amphure_id || null,
    DistrictDoc:newCustomer?.addresses[1]?.amphure_id  || null,
    email: newCustomer?.signer_email|| null,
    legalEntityRegistrationNumber: newCustomer?.addresses[0].tax_id || null,
    legalEntityRegistrationNumberDoc: newCustomer?.addresses[1]?.tax_id|| null,
    lineID: newCustomer?.signer_line_id|| null,
    personType: newCustomer?.addresses[0].is_natural_person ,
    personTypeDoc: newCustomer?.addresses[1]?.is_natural_person,
    province:  newCustomer?.addresses[0].province_id || null,
    provinceDoc:  newCustomer?.addresses[1]?.province_id || null,
    signContract: newCustomer?.signer_full_name|| null,
    signerPhone: newCustomer?.signer_phone|| null,
    signerPhoneDoc: newCustomer?.addresses[1]?.phone|| null,
    signerPosition: newCustomer?.signer_position|| null,
    tambon: newCustomer?.addresses[0].tambon_id|| null,
    tambonDoc: newCustomer?.addresses[1]?.tambon_id|| null,
    zipCode: newCustomer?.addresses[0].postal_code|| null,
    zipCodeDoc: newCustomer?.addresses[1]?.postal_code|| null,
  };
  const [personTypeLoadChecker, setPersonTypeLoadChecker] = useState(initialValues.personType);
  const [branchChecker, setBranchChecker] = useState(initialValues.branch);
  const [useFormDoc, setUseFormDoc] = useState(initialValues.companyNameDoc?true:false)
  const [personTypeLoadCheckerDoc, setPersonTypeLoadCheckerDoc] = useState(Boolean);
  const [branchCheckerDoc, setBranchCheckerDoc] = useState(Boolean);



 
  const fetchProvinces = async () => {
    try {
      const response = await newCustomerRegisterServices.getProvices();
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        console.log("else setProvinces");
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetch provinces", err);
    }
  };
  const fetchAmphure = async (value: string) => {
    try {
      const response = await newCustomerRegisterServices.getAmphure(value);
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetch fetchAmphure", err);
    }
  };

  const fetchCategoryTypes = async () => {
    try {
      const response = await newCustomerRegisterServices.getCategoryTypes();
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetch provinces", err);
    }
  };
  const fetchBusinessType = async (value: string) => {
    try {
      const response = await newCustomerRegisterServices.getBusinessTypes(
        value
      );
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetchBusinessType", err);
    }
  };

  const handleSelectProvinces = async (value: string) => {
    let newListAmphure = await fetchAmphure(value);
    setAmphure(newListAmphure);
    setProvincesSelected({ id: value });
  };

  const handleSelectProvincesDoc = async (value: string) => {
    let newListAmphure = await fetchAmphure(value);
    setAmphureDoc(newListAmphure);
    setProvincesSelectedDoc({ id: value });
  };

  const handleSelectDistrict = async (value: string) => {
    console.log("handleSelectDistrict value:", value);
    if (!provincesSelected) {
      return;
    }
    try {
      const response = await newCustomerRegisterServices.getTambon(
        provincesSelected.id,
        value
      );
      console.log("API handleSelectDistrict:", response);
      if (Array.isArray(response.data)) {
        setTambon(response.data);
      } else {
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetch handleSelectDistrict", err);
    }
  };
  const handleSelectDistrictDoc = async (value: string) => {
    console.log("handleSelectDistrict value:", value);
    if (!provincesSelectedDoc) {
      return;
    }
    try {
      const response = await newCustomerRegisterServices.getTambon(
        provincesSelectedDoc.id,
        value
      );
      if (Array.isArray(response.data)) {
        setTambonDoc(response.data);
      } else {
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetch handleSelectDistrictDoc", err);
    }
  };

  const onLoadTambon = async (provinceId: string, DistrictId: string) => {
        try {
      const response = await newCustomerRegisterServices.getTambon(
        provinceId,
        DistrictId
      );
      if (Array.isArray(response.data)) {
        setTambon(response.data);
      } else {
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetch handleSelectDistrict", err);
    }
  };
  const onLoadTambonDoc = async (provinceId: string, DistrictId: string) => {
    try {
  const response = await newCustomerRegisterServices.getTambon(
    provinceId,
    DistrictId
  );
  if (Array.isArray(response.data)) {
    setTambonDoc(response.data);
  } else {
    throw new Error("Unexpected data structure");
  }
} catch (err) {
  console.error("Failed to fetch handleSelectDistrict", err);
}
};

  const handleInputZipCode = async (valueZipCodeInput: string) => {
    console.log("handleInputZipCode :", valueZipCodeInput);

    let newProvinces = await fetchProvinces();
    if (valueZipCodeInput) {
      // try {
      const response = await newCustomerRegisterServices.postcodeAddresses(
        valueZipCodeInput
      );
      if (Array.isArray(response.data) && response.data.length > 0) {
        console.log(response.data);
        const listIdProvinceByZipCode: Set<number> = new Set();
        const listIdDritrictByZipCode: Set<number> = new Set();
        const listIdTombonByZipCode: Set<number> = new Set();

        // Collect province IDs from the response data
        response.data.forEach((element: any) => {
          if (element.amphure && element.amphure.province) {
            listIdProvinceByZipCode.add(element.amphure.province.id);
            listIdDritrictByZipCode.add(element.amphure.id);
          }
          listIdTombonByZipCode.add(element.id);
        });

        // Filter the provinces list to only include those with IDs in listIdProvinceByZipCode
        const filteredProvinces = await newProvinces.filter(
          (province: { id: number }) => listIdProvinceByZipCode.has(province.id)
        );

        await setProvinces(filteredProvinces);
        return;
      }
    }
    await setProvinces(newProvinces);
  };
  const handleInputZipCodeDoc = async (valueZipCodeInput: string) => {
    let newProvinces = await fetchProvinces();
    if (valueZipCodeInput) {
      // try {
      const response = await newCustomerRegisterServices.postcodeAddresses(
        valueZipCodeInput
      );
      if (Array.isArray(response.data) && response.data.length > 0) {
        console.log(response.data);
        const listIdProvinceByZipCode: Set<number> = new Set();
        const listIdDritrictByZipCode: Set<number> = new Set();
        const listIdTombonByZipCode: Set<number> = new Set();

        // Collect province IDs from the response data
        response.data.forEach((element: any) => {
          if (element.amphure && element.amphure.province) {
            listIdProvinceByZipCode.add(element.amphure.province.id);
            listIdDritrictByZipCode.add(element.amphure.id);
          }
          listIdTombonByZipCode.add(element.id);
        });

        // Filter the provinces list to only include those with IDs in listIdProvinceByZipCode
        const filteredProvinces = await newProvinces.filter(
          (province: { id: number }) => listIdProvinceByZipCode.has(province.id)
        );

        await setProvincesDoc(filteredProvinces);
        return;
      }
    }
    await setProvincesDoc(newProvinces);
  };

  const OnSelectPersonTyprLegalEntity = (showDisplay: boolean) => {
    setShowLegalEntitySelection(showDisplay);
    setPersonType(showDisplay);
    setPersonTypeLoadChecker(showDisplay)
  };
  const OnSelectUseAnotherAddressForDocument = (showDisplay: boolean) => {
    setshowUseAnotherAddressForDocument(showDisplay);
    setPersonTypeDoc(showDisplay);
    setUseFormDoc(showDisplay)
    if (showDisplay) {
      setUpProvicesDoc();
    }
  };
  const OnSelectShowLegalEntitySelectionForDocument = (
    showDisplay: boolean
  ) => {
    setshowLegalEntitySelectionForDocument(showDisplay);
    setBranchDoc(showDisplay);
    setPersonTypeLoadCheckerDoc(!showDisplay)
  };

  const setUpProvices = async () => {
    let provinces = await fetchProvinces();
    setProvinces(provinces);
  };
  const setUpProvicesDoc = async () => {
    let provincesDoc = await fetchProvinces();
    setProvincesDoc(provincesDoc);
  };
  const setUpCategoryTypes = async () => {
    let category = await fetchCategoryTypes();
    setCategoryTypes(category);
  };
  const handleSelectCateegory = async (value: string) => {
    let newListBusinessType = await fetchBusinessType(value);
    setBusinessTypes(newListBusinessType);
  };

  


  const handleSubmitEditCustomer = async (
    values: RegisterFormValues
  ) => {
    const payload = {
      ...values,
      personType: personType ?? values.personType,
      branch: branch ?? values.branch,
      personTypeDoc: personTypeDoc ?? values.personTypeDoc,
      branchDoc: branchDoc ?? values.branchDoc,
    };
    console.log("payload :", payload);
    let formData: FormDataType = {
      category_type_id: Number(payload.categoryType),
      business_type_id: Number(payload.businessSubType),
      business_name: payload.companyNameLabel,
      signer_full_name: payload.signContract,
      signer_position: payload.signerPosition,
      signer_phone: payload.signerPhone,
      signer_email: payload.email,
      signer_line_id: payload.lineID,
      coordinator_full_name: payload.coordinator,
      coordinator_position: payload.coordinatorPosition,
      coordinator_phone: payload.coordinatorPhone,
      map_url: "",
      is_use_another_address: Boolean(payload.personTypeDoc),
      main_address: {
        id: newCustomer?.addresses[0]?.id ?? 0,
        is_natural_person: payload.personType,
        is_head_office: payload.branch,
        branch_name: values.branchName,
        company_name: payload.companyName,
        tax_id: payload.legalEntityRegistrationNumber,
        postal_code: payload.zipCode,
        province_id: Number(payload.province),
        amphure_id: Number(payload.District),
        tambon_id: Number(payload.tambon),
        address: payload.address,
        phone: payload.coordinatorPhone,
      },
    };
    if (payload.personTypeDoc) {
      const anotherAddress = {
        id: newCustomer?.addresses[1]?.id ?? 0,
        is_natural_person: values.personTypeDoc === true,
        is_head_office: values.branchDoc === false,
        branch_name: values.branchNameDoc,
        company_name: values.companyNameDoc,
        tax_id: values.legalEntityRegistrationNumberDoc,
        postal_code: values.zipCodeDoc,
        province_id: Number(values.provinceDoc),
        amphure_id: Number(values.DistrictDoc),
        tambon_id: Number(values.tambonDoc),
        address: values.addressDoc,
        phone: values.signerPhoneDoc, // Assuming the phone field is the same for both addresses, adjust if needed
      };
      formData.another_address = anotherAddress;
    }
    try {
      const customerId = id ?? "";
      console.log(formData)
      const updateCustomerResponse =
        await newCustomerRegisterServices.updateCustomer(customerId,formData);
      console.log("updateCustomerResponse :", updateCustomerResponse);

      if (updateCustomerResponse.status === 200) {
      } else {
        console.error(
          "Failed to add customer. Status:",
          updateCustomerResponse.status
        );
      }
    } catch (error) {
      console.error("Error occurred during customer registration:", error);
    }
  };

  const validate = (values: any) => {
    const errors: any = {};

    // Validate Business Info
    if (!values.categoryType) {
      errors.categoryType = "กรุณากรอกข้อมูล";
    }
    if (!values.businessSubType) {
      errors.businessSubType = "กรุณากรอกข้อมูล";
    }
    if (!values.companyName) {
      errors.companyName = "กรุณากรอกข้อมูล";
    }
    if (!values.companyNameLabel) {
      errors.companyNameLabel = "กรุณากรอกข้อมูล";
    }
    if (!values.signerPosition) {
      errors.signerPosition = "กรุณากรอกข้อมูล";
    }
    if (!values.signerPhone) {
      errors.signerPhone = "กรุณากรอกข้อมูล";
    } else if (!/^\d{10}$/.test(values.signerPhone)) {
      errors.signerPhone = "กรุณากรอกข้อมูล";
    }
    if (!values.email) {
      errors.email = "กรุณากรอกข้อมูล";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(values.email)) {
      errors.email = "กรอก email ไม่ถูกต้อง";
    }

    // Validate Coordinator Info
    if (!values.coordinator) {
      errors.coordinator = "กรุณากรอกข้อมูล";
    }
    if (!values.coordinatorPhone) {
      errors.coordinatorPhone = "กรุณากรอกข้อมูล";
    } else if (!/^\d{10}$/.test(values.coordinatorPhone)) {
      errors.coordinatorPhone = "กรอกได้ 10 ตัว";
    }

    // Validate Address Info
    if (!values.address) {
      errors.address = "กรุณากรอกข้อมูล";
    }
    if (!values.zipCode) {
      errors.zipCode = "กรุณากรอกข้อมูล";
    }
    if (!values.province) {
      errors.province = "กรุณากรอกข้อมูล";
    }
    if (!values.District) {
      errors.District = "กรุณากรอกข้อมูล";
    }
    if (!values.tambon) {
      errors.tambon = "กรุณากรอกข้อมูล";
    }

    // Validate another address if personTypeDoc is true
    if (values.personTypeDoc) {
      if (!values.companyNameDoc) {
        errors.companyNameDoc = "กรุณากรอกข้อมูล";
      }
      if (!values.addressDoc) {
        errors.addressDoc = "กรุณากรอกข้อมูล";
      }
      if (!values.zipCodeDoc) {
        errors.zipCodeDoc = "กรุณากรอกข้อมูล";
      }
      if (!values.provinceDoc) {
        errors.provinceDoc = "กรุณากรอกข้อมูล";
      }
      if (!values.DistrictDoc) {
        errors.DistrictDoc = "กรุณากรอกข้อมูล";
      }
      if (!values.tambonDoc) {
        errors.tambonDoc = "กรุณากรอกข้อมูล";
      }
    }

    return errors;
  };

  const getNewCustomerById = async (id: string) => {
    try {
      const responseCutomer = await newCustomerRegisterServices.getNewCustomerByid(id);
      return responseCutomer.data; // Return the customer data
    } catch (error) {
      console.log("Error in getNewCustomerById:", error);
      throw error; // Optionally rethrow the error for further handling
    }
  };
  const setNewCustomerById = async () => {
    
    try {
      if (id) {
        const newCustomer = await getNewCustomerById(id);
        console.info(newCustomer);
        handleSelectCateegory(newCustomer?.getCategoryTypes)
        setNewCustomer(newCustomer); 
        OnSelectPersonTyprLegalEntity(newCustomer?.addresses[0].is_natural_person)
        handleSelectProvinces(newCustomer?.addresses[0].province_id)
        onLoadTambon(newCustomer?.addresses[0].province_id,newCustomer?.addresses[0].amphure_id)
        if (newCustomer.addresses[1]) {
          setBranchCheckerDoc(newCustomer?.addresses[1]?.branch_name ? true : false)
          setPersonTypeLoadCheckerDoc(newCustomer?.addresses[1]?.is_natural_person?true:false)
          OnSelectShowLegalEntitySelectionForDocument(newCustomer?.addresses[1]?.is_natural_person?false:true)
          OnSelectUseAnotherAddressForDocument(true)
          OnSelectPersonTyprLegalEntity(newCustomer?.addresses[1].is_natural_person)
          handleSelectProvincesDoc(newCustomer?.addresses[1].province_id)
          onLoadTambonDoc(newCustomer?.addresses[1].province_id,newCustomer?.addresses[1].amphure_id)
        }
      } else {
        console.log("No ID provided in route params.");
      }
    } catch (error) {
      console.error("Error while setting new customer:", error);
    }
  };

  useEffect(() => {
    setUpProvices();
    setUpCategoryTypes();
    if (id) {
      setNewCustomerById(); // Fetch the customer when the component mounts or the id changes
    }
    setLoadingPage(false)
    
    
  }, [id]);
  return (
    <>
      <LayoutWeb>
        {loadingPage ? (
          <LoaderPage />
        ) : (
          <>
            <div className="flex flex-col">
              <div className="mt-10 mx-auto">
                <div className="hidden md:block text-xl lg:text-2xl">
                  ข้อมูลสมัครใช้บริการเก็บขนและกำจัดมูลฝอยติดเชื้อ
                </div>
              </div>
              <Form
                onSubmit={handleSubmitEditCustomer}
                initialValues={initialValues}
                validate={validate}
                render={({ handleSubmit, values, submitting, pristine }) => (
                  <>
                    <form onSubmit={handleSubmit}>
                      <BgWhiteForm>
                        <div className="flex flex-col mx-3 md:mx-0">
                          <div className="mb-4 -ml-4 text-xl">
                            ข้อมูลใช้สำหรับการทำสัญญา
                          </div>
                          <div className="">ประเภทผู้ติดต่อ :</div>
                          <div className="flex flex-row my-4">
                            <div className="lg:basis-1/5 md:basis-1/5 basis-1/2">
                              <FieldInputWeb
                                name="personType"
                                component="radio"
                                type="radio"
                                requiredField
                                value={false}
                                onChange={() =>
                                  OnSelectPersonTyprLegalEntity(false)
                                }
                                checked={personTypeLoadChecker === false}
                              >
                                บุคคลธรรมดา
                              </FieldInputWeb>
                            </div>
                            <div className="lg:basis-1/5 md:basis-1/5 basis-1/2">
                              <FieldInputWeb
                                name="personType"
                                component="radio"
                                type="radio"
                                onChange={() =>
                                  OnSelectPersonTyprLegalEntity(true)
                                }
                                requiredField
                                value={true}
                                className="ml-2 md:ml-0"
                                checked={personTypeLoadChecker === true}
                              >
                                นิติบุคคล
                              </FieldInputWeb>
                            </div>
                          </div>

                          <div
                            className={`flex flex-row my-4 ${
                              showLegalEntitySelection ? "" : "hidden"
                            }`}
                          >
                            <div className="lg:basis-1/5 md:basis-1/5 basis-2/6">
                              <FieldInputWeb
                                name="branch"
                                component="radio"
                                type="radio"
                                requiredField
                                onChange={() => {setBranch(false);setBranchChecker(true)}}
                                checked={branchChecker === true}
                              >
                                สำนักงานใหญ่
                              </FieldInputWeb>
                            </div>
                            <div className="lg:basis-1/5 md:basis-1/5 basis-1/6">
                              <FieldInputWeb
                                name="branch"
                                component="radio"
                                type="radio"
                                className="ml-2 md:ml-0"
                                requiredField
                                onChange={() => {setBranch(true);setBranchChecker(false)}}
                                checked={branchChecker === false}
                              >
                                สาขา
                              </FieldInputWeb>
                            </div>
                            <div className="md:basis-3/5 basis-3/6">
                              <FieldInputWeb
                                name="branchName"
                                type="string"
                                placeholder="    ชื่อสาขา"
                                className="w-fit pl-4 lg:pl-0"
                              />
                            </div>
                          </div>
                          <div className="grid-project grid grid-cols-1 lg:grid-cols-2 gap-x-3">
                            <div className="">
                              <FieldInputWeb
                                name="companyName"
                                type="string"
                                label="ห้างหุ้นส่วน/บริษัท"
                                placeholder="บริษัท รวยไรขีด จำกัด"
                                requiredField
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="legalEntityRegistrationNumber"
                                type="string"
                                label="เลขทะเบียนนิติบุคคล"
                                maxLength={13}
                                requiredField
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="categoryType"
                                component="select"
                                label="หมวดหมู่สถานพยาบาล"
                                requiredField={true}
                                onChange={(e) =>
                                  handleSelectCateegory(e.target.value)
                                }
                              >
                                {/* These will be the options for the select input */}
                                <option value="" selected disabled>
                                  เลือกหมวดหมู่สถานพยาบาล
                                </option>
                                {categoryTypes.map((categoryType) => (
                                  <option
                                    key={categoryType.id}
                                    value={categoryType.id}
                                  >
                                    {categoryType.name}
                                  </option>
                                ))}
                              </FieldInputWeb>
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="businessSubType"
                                component="select"
                                label="ประเภทสถานพยาบาล"
                                requiredField={true}
                              >
                                {/* These will be the options for the select input */}
                                <option value="" disabled>
                                  {"เลือกประเภทกิจการ (เลือกหมวดหมู่ก่อน)"}
                                </option>
                                {businessTypes.map((businessType) => (
                                  <option
                                    key={businessType.id}
                                    value={businessType.id}
                                  >
                                    {businessType.name}
                                  </option>
                                ))}
                              </FieldInputWeb>
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="companyNameLabel"
                                type="string"
                                label="ชื่อสถานที่"
                                placeholder="โรงพยาบาลนิวโชคอำนวย"
                                requiredField
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="zipCode"
                                type="number"
                                label="รหัสไปรษณีย"
                                placeholder="50000"
                                requiredField
                                maxLength={5}
                                onBlur={(e) =>
                                  handleInputZipCode(e.target.value)
                                }
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="province"
                                component="select"
                                label="จังหวัด"
                                requiredField={true}
                                onChange={(e) =>
                                  handleSelectProvinces(e.target.value)
                                }
                                value={selectedProvinceId || ""}
                              >
                                <option value="" selected>
                                  เลือกจังหวัด
                                </option>
                                {provinces.map((province) => (
                                  <option key={province.id} value={province.id}>
                                    {province.name_th}
                                  </option>
                                ))}
                              </FieldInputWeb>
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="District"
                                component="select"
                                label="อําเภอ"
                                requiredField={true}
                                onChange={(e) =>
                                  handleSelectDistrict(e.target.value)
                                }
                              >
                                {/* These will be the options for the select input */}
                                <option value="" disabled selected>
                                  {"เลือกอำเภอ (โปรดเลือกจังหวัดก่อน)"}
                                </option>
                                {amphures.map((amphure) => (
                                  <option key={amphure.id} value={amphure.id}>
                                    {amphure.name_th}
                                  </option>
                                ))}
                              </FieldInputWeb>
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="tambon"
                                component="select"
                                label="ตำบล"
                                requiredField={true}
                              >
                                {/* These will be the options for the select input */}
                                <option value="" disabled selected>
                                  {"เลือกตำบล (โปรดเลือกอำเภอก่อน)"}
                                </option>
                                {tambons.map((tambon) => (
                                  <option key={tambon.id} value={tambon.id}>
                                    {tambon.name_th}
                                  </option>
                                ))}
                              </FieldInputWeb>
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="address"
                                type="string"
                                label="ที่อยู่"
                                component="textarea"
                                requiredField={true}
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="signContract"
                                type="string"
                                label="ผู้มีอำนาจรับผิดชอบ/ลงนามสัญญา"
                                placeholder="ท่านประธาน"
                                requiredField
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="signerPosition"
                                type="string"
                                label="ตำแหน่ง"
                                placeholder="ตำแหน่ง"
                                requiredField
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="signerPhone"
                                type="number"
                                label="เบอร์โทรศัพท์"
                                maxLength={10}
                                requiredField
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="email"
                                type="email"
                                label="อีเมล"
                                requiredField
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="lineID"
                                type="string"
                                label="LINE ID"
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="coordinator"
                                type="string"
                                label="ผู้ประสานงาน"
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="coordinatorPosition"
                                type="string"
                                label="ตำแหน่ง"
                                placeholder="ตำแหน่ง"
                              />
                            </div>
                            <div className="">
                              <FieldInputWeb
                                name="coordinatorPhone"
                                type="number"
                                label="เบอร์โทรศัพท์"
                                maxLength={10}
                                requiredField
                              />
                            </div>
                          </div>
                        </div>
                      </BgWhiteForm>
                      <BgWhiteForm>
                        <div className="flex flex-col mx-3 md:mx-0">
                          <div className="mb-4 -ml-4 text-xl">
                            ข้อมูลใช้สำหรับการออกเอกสารใบแจ้งหนี้/ใบเสร็จรับเงิน
                          </div>
                          <div className="flex flex-row my-4">
                            <div className="lg:basis-1/5 md:basis-1/2">
                              <FieldInputWeb
                                name="FormDoc"
                                component="radio"
                                type="radio"
                                requiredField
                                value={false}
                                onChange={() =>
                                  OnSelectUseAnotherAddressForDocument(false)
                                }
                                checked={useFormDoc === false}
                              >
                                ใช้ข้อมูลข้างต้น
                              </FieldInputWeb>
                            </div>
                            <div className="lg:basis-1/5 md:basis-1/2">
                              <FieldInputWeb
                                name="FormDoc"
                                component="radio"
                                type="radio"
                                requiredField
                                value={true}
                                onChange={() =>
                                  OnSelectUseAnotherAddressForDocument(true)
                                }
                                className="ml-2 md:ml-0"
                                checked={useFormDoc === true}
                              >
                                ใช้ข้อมูลอื่น
                              </FieldInputWeb>
                            </div>
                          </div>
                          <div
                            className={`${
                              showUseAnotherAddressForDocument ? "" : "hidden"
                            }`}
                          >
                            <div className="">ประเภทผู้ติดต่อ :</div>
                            <div className="flex flex-row my-4">
                              <div className="lg:basis-1/5 md:basis-1/2">
                                <FieldInputWeb
                                  name="personTypeDoc"
                                  component="radio"
                                  type="radio"
                                  requiredField
                                  onChange={() =>
                                    OnSelectShowLegalEntitySelectionForDocument(
                                      false
                                    )
                                  }
                                  checked={personTypeLoadCheckerDoc === true}
                                >
                                  บุคคลธรรมดา
                                </FieldInputWeb>
                              </div>
                              <div className="lg:basis-1/5 md:basis-1/2">
                                <FieldInputWeb
                                  name="personTypeDoc"
                                  component="radio"
                                  type="radio"
                                  onChange={() =>
                                    OnSelectShowLegalEntitySelectionForDocument(
                                      true
                                    )
                                  }
                                  requiredField
                                  value="นิติบุคคล"
                                  className="ml-2 md:ml-0"
                                  checked={personTypeLoadCheckerDoc === false}
                                >
                                  นิติบุคคล
                                </FieldInputWeb>
                              </div>
                            </div>
                            <div
                              className={`flex flex-row my-4 ${
                                showLegalEntitySelectionForDocument
                                  ? ""
                                  : "hidden"
                              }`}
                            >
                              <div className="lg:basis-1/5 md:basis-1/5 basis-2/6">
                                <FieldInputWeb
                                  name="branchDoc"
                                  component="radio"
                                  type="radio"
                                  onChange={() => {setBranchDoc(false);setBranchCheckerDoc(false)}}
                                  checked={branchCheckerDoc === false}
                                >
                                  สำนักงานใหญ่
                                </FieldInputWeb>
                              </div>
                              <div className="lg:basis-1/5 md:basis-1/5 basis-1/6">
                                <FieldInputWeb
                                  name="branchDoc"
                                  component="radio"
                                  type="radio"
                                  className="ml-2 md:ml-0"
                                  onChange={() => {setBranchDoc(true);setBranchCheckerDoc(true)}}
                                  checked={branchCheckerDoc === true}
                                >
                                  สาขา
                                </FieldInputWeb>
                              </div>
                              <div className="md:basis-3/5 basis-3/6">
                                <FieldInputWeb
                                  name="branchNameDoc"
                                  type="string"
                                  placeholder="ชื่อสาขา"
                                  className="w-fit pl-4 lg:pl-0"
                                />
                              </div>
                            </div>
                            <div className="grid-project grid grid-cols-1 lg:grid-cols-2 gap-x-3">
                              <div className="">
                                <FieldInputWeb
                                  name="companyNameDoc"
                                  type="string"
                                  label="ห้างหุ้นส่วน/บริษัท"
                                  placeholder="บริษัท รวยไรขีด จำกัด"
                                  requiredField
                                />
                              </div>
                              <div className="">
                                <FieldInputWeb
                                  name="legalEntityRegistrationNumberDoc"
                                  type="string"
                                  label="เลขทะเบียนนิติบุคคล"
                                  placeholder="1234567890123"
                                  maxLength={13}
                                  requiredField
                                />
                              </div>
                              <div className="">
                                <FieldInputWeb
                                  name="zipCodeDoc"
                                  type="number"
                                  label="รหัสไปรษณีย"
                                  placeholder="50000"
                                  requiredField
                                  maxLength={5}
                                  onBlur={(e) =>
                                    handleInputZipCodeDoc(e.target.value)
                                  }
                                />
                              </div>
                              <div className="">
                                <FieldInputWeb
                                  name="provinceDoc"
                                  component="select"
                                  label="จังหวัด"
                                  onChange={(e) =>
                                    handleSelectProvincesDoc(e.target.value)
                                  }
                                  value={selectedProvinceIdDoc || ""}
                                >
                                  {/* These will be the options for the select input */}
                                  <option value="" disabled selected>
                                    เลือกจังหวัด
                                  </option>
                                  {provincesDoc.map((provinceDoc) => (
                                    <option
                                      key={provinceDoc.id}
                                      value={provinceDoc.id}
                                    >
                                      {provinceDoc.name_th}
                                    </option>
                                  ))}
                                </FieldInputWeb>
                              </div>
                              <div className="">
                                <FieldInputWeb
                                  name="DistrictDoc"
                                  component="select"
                                  label="อําเภอ"
                                  onChange={(e) =>
                                    handleSelectDistrictDoc(e.target.value)
                                  }
                                >
                                  {/* These will be the options for the select input */}
                                  <option value="" disabled selected>
                                    {"เลือกอำเภอ (โปรดเลือกจังหวัดก่อน)"}
                                  </option>
                                  {amphuresDoc.map((amphure) => (
                                    <option key={amphure.id} value={amphure.id}>
                                      {amphure.name_th}
                                    </option>
                                  ))}
                                </FieldInputWeb>
                              </div>
                              <div className="">
                                <FieldInputWeb
                                  name="tambonDoc"
                                  component="select"
                                  label="ตำบล"
                                >
                                  <option value="" disabled selected>
                                    {"เลือกตำบล (โปรดเลือกอำเภอก่อน)"}
                                  </option>
                                  {tambonsDoc.map((tambon) => (
                                    <option key={tambon.id} value={tambon.id}>
                                      {tambon.name_th}
                                    </option>
                                  ))}
                                </FieldInputWeb>
                              </div>
                              <div className="">
                                <FieldInputWeb
                                  name="addressDoc"
                                  type="string"
                                  label="ที่อยู่"
                                  component="textarea"
                                  requiredField={true}
                                />
                              </div>
                              <div className="">
                                <FieldInputWeb
                                  name="signerPhoneDoc"
                                  type="number"
                                  label="เบอร์โทรศัพท์"
                                  requiredField
                                  maxLength={10}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </BgWhiteForm>

                      <div className="mt-8 mb-16 text-center">
                        <button
                          type="submit"
                          className="bg-[#3758F9] text-white p-2 rounded w-[20%]"
                          disabled={submitting || pristine}
                        >
                          บันทึกการแก้ไขข้อมูล
                        </button>
                      </div>
                    </form>
                  </>
                )}
              />
            </div>
          </>
        )}
      </LayoutWeb>
    </>
  );
};

export default NewCustomerDetail;
