import React, { ReactNode, useState } from "react";
import Sidebar from "./sidebar/Sidebar";
import Navbar from "./navbar/Navbar";

interface LayoutWebProps {
  children: ReactNode;
}

const LayoutWeb: React.FC<LayoutWebProps> = ({ children }) => {
  return (
    <>
      <div className="max-w-ful flex flex-row min-h-screen">
        <div className="w-[255px]">
          <Sidebar />
        </div>
        <div className="w-full flex-col">
          <Navbar />
          <div className="flex flex-1">
            <main className="flex-1 p-4">
              {children}
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default LayoutWeb;
