import LayoutWeb from "../components/LayoutWeb";
import "../index.css";

const Home = () => {
  return (
    <>
      <LayoutWeb>
        <h1 className="text-3xl font-bold">New Chokumnuai</h1>
      </LayoutWeb>
    </>
  );
};

export default Home;
