import React from "react";
import { logout, setUserInfo } from "../../stores/slices/auth";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const Navbar: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };
  return (
    <header className="bg-blue-500 text-white p-4 flex justify-between">
      <h1 className="text-2xl font-bold">Navbar</h1>
      <div className="" onClick={handleLogout}>
        Logout
      </div>
    </header>
  );
};

export default Navbar;
