import React, { useState } from "react";
import { Link } from "react-router-dom";

const Sidebar: React.FC = () => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleSubMenuToggle = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  return (
    <div className="p-4">
      <Link to="/">
      <h2 className="text-lg font-semibold">Sidebar Menu</h2>
      </Link>
      <ul className="space-y-2">
        <li>
          <button className="w-full text-left" onClick={handleSubMenuToggle}>
            Main Item 1 {isSubMenuOpen ? '▲' : '▼'}
          </button>
          <div
            className={`transition-[max-height] ease-in-out duration-300 overflow-hidden ${
              isSubMenuOpen ? 'max-h-40' : 'max-h-0'
            }`}
          >
            <ul className="pl-4 space-y-1">
              <li className="text-sm">Sub Item 1.1</li>
              <li className="text-sm">Sub Item 1.2</li>
              <li className="text-sm">Sub Item 1.3</li>
            </ul>
          </div>
        </li>
        <Link to="/register">
        <li> Costomer Register Page</li>
        </Link>
        <li>Main Item 3</li>
      </ul>
    </div>
  );
};

export default Sidebar;
