import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FormikErrors } from "formik";
import * as Yup from "yup";
import newCustomerRegisterServices from "../../services/newCustomerApi";

// Define form types
interface FormValues {
  contactType: string;
  office: string;
  branchOffice: string;
  branch_code: string;
  company_name: string;
  tax_id: string;
  category_type: string;
  business_type:string;

  lastName: string;
  address: string;
  phoneNumber: string;
  gender: string;
  study: boolean;
  selfPortrait: File | null;
}
interface CategoryTypes {
  id: number;
  name: string;
}
interface BusinessType {
  id: number;
  category_type_id: number;
  name: string;
}

// Validation schema using Yup
const validationSchema = Yup.object({
  contactType: Yup.string().required("กรุณาเลือกประเภทผู้ติดต่อ"),
  office: Yup.string().when("contactType", {
    is: (contactType: string) => contactType === "นิติบุคคล",
    then: (schema: Yup.StringSchema) =>
      schema.required("กรุณาระบุเลือกสำนักงาน"),
    otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
  }),
  branch_code: Yup.string().when("office", {
    is: (office: string) => office === "สาขา",
    then: (schema: Yup.StringSchema) => schema.required("กรุณาระบุรหัสสาขา"),
    otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
  }),
  branchOffice: Yup.string().when("office", {
    is: (office: string) => office === "สาขา",
    then: (schema: Yup.StringSchema) => schema.required("กรุณาระบุชื่อสาขา"),
    otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
  }),
  company_name: Yup.string().required("กรุณากรอกข้อมูล"),
  tax_id: Yup.string()
    .matches(/^[0-9]+$/, "ต้องเป็นตัวเลขเท่านั้น")
    .length(13, "ต้องมี 13 หลัก")
    .required("กรุณากรอกข้อมูล"),
  category_type: Yup.string().required("กรุณาเลือกหมวดหมู่"),
  business_type: Yup.string().required("กรุณาเลือกสถานพยาบาล"),
  address: Yup.string().required("Address is required"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, "Must be at least 10 digits")
    .required("Phone Number is required"),
  gender: Yup.string().required("Please select your gender"),
  study: Yup.boolean().oneOf([true], "Please confirm if you're studying"),
});

const RegistrationForm = () => {
  const [categoryTypes, setCategoryTypes] = useState<CategoryTypes[]>([]);
  const [businessTypes, setBusinessTypes] = useState<BusinessType[]>([]);


  const initialValues: FormValues = {
    contactType: "",
    office: "",
    branchOffice: "",
    branch_code: "",
    company_name: "",
    tax_id: "",
    category_type: "",
    business_type:"",
    lastName: "",
    address: "",
    phoneNumber: "",
    gender: "",
    study: false,
    selfPortrait: null,
  };

  // Create refs for inputs to enable focusing
  const contactTypeRef = useRef<HTMLInputElement>(null);
  const officeRef = useRef<HTMLInputElement>(null);
  const branchOfficeRef = useRef<HTMLInputElement>(null);
  const branch_codeRef = useRef<HTMLInputElement>(null);
  const company_nameRef = useRef<HTMLInputElement>(null);
  const tax_idRef = useRef<HTMLInputElement>(null);
  const category_typeRef = useRef<HTMLInputElement>(null);
  const business_typeRef = useRef<HTMLInputElement>(null);

  const lastNameRef = useRef<HTMLInputElement>(null);
  const addressRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const genderRef = useRef<HTMLInputElement>(null);

  // Function to focus the first invalid field
  const focusFirstError = (errors: FormikErrors<FormValues>) => {
    if (errors.contactType && contactTypeRef.current) {
      contactTypeRef.current.focus();
    } else if (errors.office && officeRef.current) {
      officeRef.current.focus();
    } else if (errors.branch_code && branch_codeRef.current) {
      branch_codeRef.current.focus();
    } else if (errors.branchOffice && branchOfficeRef.current) {
      branchOfficeRef.current.focus();
    } else if (errors.company_name && company_nameRef.current) {
      company_nameRef.current.focus();
    } else if (errors.tax_id && tax_idRef.current) {
      tax_idRef.current.focus();
    } else if (errors.category_type && category_typeRef.current) {
      category_typeRef.current.focus();
    } else if (errors.business_type && business_typeRef.current) {
      business_typeRef.current.focus();

    } else if (errors.address && addressRef.current) {
      addressRef.current.focus();
    } else if (errors.phoneNumber && phoneNumberRef.current) {
      phoneNumberRef.current.focus();
    } else if (errors.gender && genderRef.current) {
      genderRef.current.focus();
    } else if (errors.study) {
      // This will focus on the checkbox if there's an error related to 'study'
      // Note: Since it's a checkbox, focusing on it may not have a visible effect.
      if (genderRef.current) {
        genderRef.current.focus();
      }
    }
  };

  const fetchCategoryTypes = async () => {
    try {
      const response = await newCustomerRegisterServices.getCategoryTypes();
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetch provinces", err);
    }
  };
  const setUpCategoryTypes = async () => {
    let category = await fetchCategoryTypes();
    setCategoryTypes(category);
  };

  const fetchBusinessType = async (value: string) => {
    try {
      const response = await newCustomerRegisterServices.getBusinessTypes(
        value
      );
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetchBusinessType", err);
    }
  };

  const handleSelectCateegory = async (value: string) => {
    let newListBusinessType = await fetchBusinessType(value);
    setBusinessTypes(newListBusinessType);
  };

  useEffect(() => {
    setUpCategoryTypes();
  }, []);

  return (
    <>
      <div className="container mx-auto p-4">
        <div className="card rounded-2xl shadow-md my-2 p-4">
          <div className="text-2xl text-gray-600 mb-4">ข้อมูลการทำสัญญา</div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              console.log("Form data", values);
            }}
          >
            {({ setFieldValue, values, errors, touched, handleSubmit }) => (
              <Form
                className="space-y-6"
                onSubmit={(e) => {
                  e.preventDefault();
                  focusFirstError(errors); // Focus on the first invalid input
                  handleSubmit(e);
                }}
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="md:col-span-2 xl:col-span-2 grid grid-cols-2 md:grid-cols-8 xl:grid-cols-10 gap-4 items-center">
                    <div className="col-span-2 md:col-span-8 xl:col-span-10">
                      <label className="block text-md font-medium text-gray-700 mb-2">
                        ประเภทผู้ติดต่อ :
                      </label>
                    </div>
                    <div className="md:col-span-2 xl:col-span-2">
                      <div className="flex space-x-4">
                        <label className="inline-flex items-center">
                          <Field
                            type="radio"
                            name="contactType"
                            value="บุคคลธรรมดา"
                            innerRef={contactTypeRef}
                            className="form-radio"
                          />
                          <span className="ml-2 text-md">บุคคลธรรมดา</span>
                        </label>
                      </div>
                    </div>
                    <div className="md:col-span-2 xl:col-span-2">
                      <div className="flex space-x-4">
                        <label className="inline-flex items-center">
                          <Field
                            type="radio"
                            name="contactType"
                            value="นิติบุคคล"
                            className="form-radio"
                          />
                          <span className="ml-2 text-md">นิติบุคคล</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-span-full">
                      <ErrorMessage
                        name="contactType"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                    {values.contactType === "นิติบุคคล" && (
                      <>
                        <div className="md:col-start-3 md:col-span-2 xl:col-start-3 xl:col-span-2">
                          <div className="flex space-x-4">
                            <label className="inline-flex items-center">
                              <Field
                                type="radio"
                                name="office"
                                value="สำนักงานใหญ่"
                                innerRef={contactTypeRef}
                                className="form-radio"
                              />
                              <span className="h-9 ml-2 text-md flex items-center">
                                สำนักงานใหญ่
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="md:col-span-1 xl:col-span-1">
                          <div className="flex space-x-4">
                            <label className="inline-flex items-center">
                              <Field
                                type="radio"
                                name="office"
                                value="สาขา"
                                className="form-radio"
                              />
                              <span className="h-9 ml-2 text-md flex items-center">
                                สาขา
                              </span>
                            </label>
                          </div>
                        </div>
                        {values.office === "สาขา" && (
                          <>
                            <div className="col-span-2 md:col-span-3 xl:col-span-5 md:flex ">
                              <Field
                                name="branch_code"
                                innerRef={branch_codeRef}
                                className={`h-8 mt-1 block w-full md:w-[45%]  border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md border-[1px] rounded-[6px] ${
                                  errors.branch_code && touched.branch_code
                                    ? "border-red-500"
                                    : ""
                                }`}
                                placeholder="   รหัสสาขา"
                              />
                              <Field
                                name="branchOffice"
                                innerRef={branchOfficeRef}
                                className={`h-8 md:ml-2 mt-3 md:mt-1 block w-full md:w-[45%]  border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md border-[1px] rounded-[6px] ${
                                  errors.branchOffice && touched.branchOffice
                                    ? "border-red-500"
                                    : ""
                                }`}
                                placeholder="   ชื่อสาขา"
                              />
                            </div>
                          </>
                        )}
                        <div className="md:col-start-3 md:col-span-2 xl:col-start-3 xl:col-span-3">
                          <ErrorMessage
                            name="office"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        <div className="col-span-2 md:col-span-2 xl:col-span-2">
                          <ErrorMessage
                            name="branch_code"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        <div className="col-span-2 md:pl-[20%] md:col-span-2 xl:pl-[14%] xl:col-span-2">
                          <ErrorMessage
                            name="branchOffice"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                      </>
                    )}
                  </div>

                  <div className="items-center m-1">
                    <label className="block text-md font-medium text-gray-700">
                      {values.contactType === "บุคคลธรรมดา"
                        ? "ชื่อ - นามสกุล"
                        : "ห้างหุ้นส่วน/บริษัท"}
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <Field
                      name="company_name"
                      innerRef={company_nameRef}
                      className={`h-8 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md border-[1px] rounded-[6px] ${
                        errors.company_name && touched.company_name
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="company_name"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="items-center m-1">
                    <label className="block text-md font-medium text-gray-700">
                      {values.contactType === "บุคคลธรรมดา"
                        ? "เลขประจำตัวประชาชน"
                        : "เลขทะเบียนนิติบุคคล"}
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <Field
                      name="tax_id"
                      innerRef={tax_idRef}
                      maxLength={13}
                      className={`h-8 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                        errors.tax_id && touched.tax_id ? "border-red-500" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="tax_id"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div>
                    <label className="block text-md font-medium text-gray-700">
                      หมวดหมู่สถานพยาบาล
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <Field
                      as="select"
                      name="category_type"
                      className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                        errors.category_type && touched.category_type ? "border-red-500" : ""
                      }`}
                      innerRef={category_typeRef}
                      onChange={(e: { target: { value: string; }; }) =>
                        handleSelectCateegory(e.target.value)
                      }
                    >
                      <option value={0} disabled selected>
                        เลือกหมวดหมู่
                      </option>
                      {categoryTypes.map((categoryType) => (
                        <option key={categoryType.id} value={categoryType.id}>
                          {categoryType.name}
                        </option>
                      ))}
                      <ErrorMessage
                        name="category_type"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </Field>
                  </div>

                  <div>
                    <label className="block text-md font-medium text-gray-700">
                      สถานพยาบาล
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <Field
                      as="select"
                      name="business_type"
                      className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                        errors.business_type && touched.business_type ? "border-red-500" : ""
                      }`}
                      innerRef={business_typeRef}
                    >
                      <option disabled selected>
                        {"เลือกสถานพยาบาล (เลือกหมวดหมู่ก่อน)"}
                      </option>
                      {businessTypes.map((businessType) => (
                        <option key={businessType.id} value={businessType.id}>
                          {businessType.name}
                        </option>
                      ))}
                      <ErrorMessage
                        name="business_type"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </Field>
                  </div>

                  {/* Last Name */}
                  <div className="items-center m-1">
                    <label className="block text-md font-medium text-gray-700">
                      Last Name
                    </label>
                    <Field
                      name="lastName"
                      innerRef={lastNameRef}
                      className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                        errors.lastName && touched.lastName
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  {/* Address */}
                  <div className="items-center m-1">
                    <label className="block text-md font-medium text-gray-700">
                      Address
                    </label>
                    <Field
                      name="address"
                      innerRef={addressRef}
                      className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                        errors.address && touched.address
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  {/* Phone Number */}
                  <div className="items-center m-1">
                    <label className="block text-md font-medium text-gray-700">
                      Phone Number
                    </label>
                    <Field
                      name="phoneNumber"
                      innerRef={phoneNumberRef}
                      className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                        errors.phoneNumber && touched.phoneNumber
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="phoneNumber"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  {/* Gender */}
                  <div className="items-center m-1">
                    <label className="block text-md font-medium text-gray-700">
                      Gender
                    </label>
                    <div className="flex space-x-4">
                      <label className="inline-flex items-center">
                        <Field
                          type="radio"
                          name="gender"
                          value="Male"
                          innerRef={genderRef}
                          className="form-radio"
                        />
                        <span className="ml-2">Male</span>
                      </label>
                      <label className="inline-flex items-center">
                        <Field
                          type="radio"
                          name="gender"
                          value="Female"
                          className="form-radio"
                        />
                        <span className="ml-2">Female</span>
                      </label>
                      <label className="inline-flex items-center">
                        <Field
                          type="radio"
                          name="gender"
                          value="Other"
                          className="form-radio"
                        />
                        <span className="ml-2">Other</span>
                      </label>
                    </div>
                    <ErrorMessage
                      name="gender"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  {/* Study */}
                  <div className="items-center m-1">
                    <label className="inline-flex items-center">
                      <Field
                        type="checkbox"
                        name="study"
                        className="form-checkbox h-5 w-5 text-indigo-600"
                      />
                      <span className="ml-2 text-md">
                        I am currently studying
                      </span>
                    </label>
                    <ErrorMessage
                      name="study"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  {/* Self-Portrait */}
                  <div className="items-center m-1">
                    <label className="block text-md font-medium text-gray-700">
                      Upload Self-Portrait
                    </label>
                    <input
                      type="file"
                      name="selfPortrait"
                      className="mt-1 block w-full text-md text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-md file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
                      onChange={(event) => {
                        if (event.currentTarget.files) {
                          setFieldValue(
                            "selfPortrait",
                            event.currentTarget.files[0]
                          );
                        }
                      }}
                    />
                    {/* No validation for this field */}
                  </div>

                  <button
                    type="submit"
                    className="mt-6 w-fit bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700"
                  >
                    Submit
                  </button>
                  <div>
                    <h1>sdhkl</h1>
                  </div>
                  <div>
                    <h1>sdhkl</h1>
                  </div>
                  <div>
                    <h1>sdhkl</h1>
                  </div>
                  <div>
                    <h1>sdhkl</h1>
                  </div>
                  <div>
                    <h1>sdhkl</h1>
                  </div>
                  <div></div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default RegistrationForm;
