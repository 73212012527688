import React from "react";
import { Navigate, Outlet } from "react-router-dom"; // Import Navigate and Outlet
import { getStorage } from "../utils/storage";

const PrivateRoute = () => {
  const token = getStorage("token");

  // Check if token exists, otherwise redirect to login
  return token ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
