import React, { useEffect, useState } from "react";
import NewCustomerService from "../../services/newCustomerApi";
import { GetNewCustomerFilter } from "../../types/customer";
import { Link } from "react-router-dom";

const TatalNewRegister = () => {
  const [totalRegis, settotalRegis] = useState<Number>();
  const fetchNewCustomer = async (payload: GetNewCustomerFilter) => {
    NewCustomerService.getNewCustomers(payload).then(
      async (res) => {
        if (res.status === 200) {
          settotalRegis(res.data.total);
        }
        if (res.status === 400) {
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  useEffect(() => {
    let payload: GetNewCustomerFilter = {
      perPage: 0,
      page: 1,
      isNewCustomer: true,
      startDate: "",
      endDate: "",
      search: "",
    };
    fetchNewCustomer(payload);
  }, []);

  return (
    <>
      <div className="card w-[100%] p-4 rounded-2xl shadow-md">
        <Link to={`/customers`}>
          <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
            <div className="p-4 bg-green-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                ></path>
              </svg>
            </div>
            <div className="px-4 text-gray-700">
              <h3 className="text-3lx tracking-wider">จำนวนผู้ลงทะเบียนใหม่</h3>
              <p className="text-3xl">
                {typeof totalRegis === "number"
                  ? new Intl.NumberFormat().format(totalRegis)
                  : "0"}
              </p>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default TatalNewRegister;
