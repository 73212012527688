import React from "react";
import LayoutWeb from "../components/LayoutWeb";
import Logo from "../assets/Rectangle245.svg";
import { Field, Form } from "react-final-form";
import FieldInputWeb from "../components/FieldInputWeb";
import BgWhiteForm from "../components/BgWhiteForm";

const required = (value: any) => (value ? undefined : "This field is required");
const NewCostomerRegister: React.FC = () => {
  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log("Selected value:", event.target.value);
  };
  return (
    <>
      <div className="max-w-full mx-auto">
        <div className="max-w-full h-9 lg:h-14 bg-gradient-to-r from-[#ffffff] via-[#8AC2F5] via-[#54a8f7] to-[#1C00C6]">
          <div className="max-w-screen-lg mx-auto">
            <div className="flex flex-col font-kanit">
              <div className="flex justify-between h-3 md:h-8 lg:h-14 mt-1 lg:mt-0">
                <div>
                  <img
                    src={Logo}
                    alt="icon-notice"
                    className="w-14 md:w-16 lg:w-28 mx-auto absolute top-0 left-[4%]"
                  />
                </div>
                <div className="justify-end text-lg md:text-lg lg:text-2xl text-[#FFF] mt-auto mb-auto  mr-4 md:mr-5 lg:mr-0">
                  บริษัท นิวโชคอำนวย เชียงใหม่ จำกัด
                </div>
              </div>

              <div className="mt-10 mb-10 mx-auto">
                <div className="block md:hidden text-2xl mx-auto text-center">
                  <div>กรอกข้อมูลเพื่อลงทะเบียนสมัคร</div>
                  <div>ใช้บริการเก็บขนและกำจัดมูลฝอยติดเชื้อ</div>
                </div>
                <div className="hidden md:block text-2xl lg:text-3xl">
                  กรอกข้อมูลเพื่อลงทะเบียนสมัครใช้บริการเก็บขนและกำจัดมูลฝอยติดเชื้อ
                </div>
              </div>
              {/* React Final Form */}
              <Form
                onSubmit={(values) => {
                  console.log(values);
                }}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <BgWhiteForm>
                      <div className="flex flex-col">
                        <div className="">ข้อมูลใช้สำหรับการทำสัญญา</div>
                        <div className="">ประเภทผู้ติดต่อ :</div>

                        <div className="flex flex-row">
                          <div className="lg:basis-1/5 md:basis-1/2">
                            <label>
                              <Field
                                name="personType"
                                component="input"
                                type="radio"
                                value="บุคคลธรรมดา"
                                validate={required}
                              />
                              บุคคลธรรมดา
                            </label>
                          </div>
                          <div className="lg:basis-1/5 md:basis-1/2">
                            <label>
                              <Field
                                name="personType"
                                component="input"
                                type="radio"
                                value="นิติบุคล"
                                validate={required}
                              />
                              นิติบุคล
                            </label>
                          </div>
                        </div>

                        <div className="flex flex-row">
                          <div className="lg:basis-1/5 md:basis-1/2"></div>
                          <div className="lg:basis-1/5 md:basis-1/2">
                            <label>
                              <Field
                                name="branch"
                                component="input"
                                type="radio"
                                value="สำนักงานใหญ่"
                                validate={required}
                              />
                              สำนักงานใหญ่
                            </label>
                          </div>
                          <div className="lg:basis-1/5 md:basis-1/2">
                            <label>
                              <Field
                                name="branch"
                                component="input"
                                type="radio"
                                value="สาขา"
                                validate={required}
                              />
                              สาขา
                            </label>
                          </div>
                          <div className="lg:basis-1/5 md:basis-1/2">
                            <FieldInputWeb
                              name="branchName"
                              type="string"
                              label=""
                              placeholder="เชียงใหม่"
                            />
                          </div>
                        </div>
                        <div className="grid-project grid grid-cols-1 lg:grid-cols-2 gap-x-3">
                          <div className="">
                            <FieldInputWeb
                              name="companyName"
                              type="string"
                              label="ห้างหุ้นส่วน/บริษัท"
                              placeholder="บริษัท รวยไรขีด จำกัด"
                              requiredField
                            />
                          </div>
                          <div className="">
                            <FieldInputWeb
                              name="legalEntityRegistrationNumber"
                              type="string"
                              label="เลขทะเบียนนิติบุคคล"
                              placeholder="1-0101-01010-10-1"
                              requiredField
                            />
                          </div>
                          <div className="">
                            <FieldInputWeb
                              name="businessType"
                              component="select"
                              label="ประเภทกิจการ"
                              requiredField={true}
                              onSelect={handleSelect}
                            >
                              {/* These will be the options for the select input */}
                              <option value="" disabled>
                                เลือกประเภทกิจการ
                              </option>
                              <option value="web">Web Development</option>
                              <option value="mobile">Mobile App</option>
                              <option value="desktop">
                                Desktop Application
                              </option>
                            </FieldInputWeb>
                          </div>
                          <div className="">
                            <FieldInputWeb
                              name="companyName"
                              type="string"
                              label="ชื่อ"
                              placeholder="โรงพยาบาลนิวโชคอำนวย"
                              requiredField
                            />
                          </div>
                          <div className="">
                            <FieldInputWeb
                              name="zipCode"
                              type="string"
                              label="รหัสไปรษณีย"
                              placeholder="57000"
                              requiredField
                            />
                          </div>
                          <div className="">
                            <FieldInputWeb
                              name="province"
                              component="select"
                              label="จังหวัด"
                              requiredField={true}
                              onSelect={handleSelect}
                            >
                              {/* These will be the options for the select input */}
                              <option value="" disabled selected>
                                เลือกจังหวัด
                              </option>
                              <option value="web">Web Development</option>
                              <option value="mobile">Mobile App</option>
                              <option value="desktop">
                                Desktop Application
                              </option>
                            </FieldInputWeb>
                          </div>
                          <div className="">
                            <FieldInputWeb
                              name="District"
                              component="select"
                              label="อําเภอ"
                              requiredField={true}
                              onSelect={handleSelect}
                            >
                              {/* These will be the options for the select input */}
                              <option value="" disabled selected>
                                เลือกอำเภอ
                              </option>
                              <option value="web">Web Development</option>
                              <option value="mobile">Mobile App</option>
                              <option value="desktop">
                                Desktop Application
                              </option>
                            </FieldInputWeb>
                          </div>
                          <div className="">
                            <FieldInputWeb
                              name="tambon"
                              component="select"
                              label="ตำบล"
                              requiredField={true}
                              onSelect={handleSelect}
                            >
                              {/* These will be the options for the select input */}
                              <option value="" disabled selected>
                                เลือกตำบล
                              </option>
                              <option value="web">Web Development</option>
                              <option value="mobile">Mobile App</option>
                              <option value="desktop">
                                Desktop Application
                              </option>
                            </FieldInputWeb>
                          </div>
                          <div className="lg:col-span-2">
                            <FieldInputWeb
                              name="address"
                              type="string"
                              label="ที่อยู่"
                              component="textarea"
                              requiredField={true}
                            />
                          </div>
                          <div className="">
                            <FieldInputWeb
                              name="signContract"
                              type="string"
                              label="ผู้มีอำนาจรับผิดชอบ/ลงนามสัญญา"
                              placeholder="ท่านประธาน"
                              requiredField
                            />
                          </div>
                          <div className="">
                            <FieldInputWeb
                              name="signerPosition"
                              type="string"
                              label="ตำแหน่ง"
                              placeholder="ตำแหน่ง"
                              requiredField
                            />
                          </div>
                          <div className="">
                            <FieldInputWeb
                              name="signerPhone"
                              type="number"
                              label="เบอร์โทรศัพท์"
                              requiredField
                            />
                          </div>
                          <div className="">
                            <FieldInputWeb
                              name="email"
                              type="email"
                              label="อีเมล"
                              requiredField
                            />
                          </div>
                          <div className="">
                            <FieldInputWeb
                              name="lineID"
                              type="string"
                              label="LINE ID"
                            />
                          </div>
                          <div className="">
                            <FieldInputWeb
                              name="coordinator"
                              type="string"
                              label="ผู้ประสานงาน"
                            />
                          </div>
                          <div className="">
                            <FieldInputWeb
                              name="coordinatorPosition"
                              type="string"
                              label="ตำแหน่ง"
                              placeholder="ตำแหน่ง"
                            />
                          </div>
                          <div className="">
                            <FieldInputWeb
                              name="coordinatorPhone"
                              type="number"
                              label="เบอร์โทรศัพท์"
                              requiredField
                            />
                          </div>
                        </div>
                      </div>
                    </BgWhiteForm>
                    <BgWhiteForm>
                      <div className="flex flex-col">
                        <div className="">
                          ข้อมูลใช้สำหรับการออกเอกสารใบแจ้งหนี้/ใบเสร็จรับเงิน
                        </div>
                        <div className="flex flex-row">
                          <div className="lg:basis-1/5 md:basis-1/2">
                            <label>
                              <Field
                                name="personType"
                                component="input"
                                type="radio"
                                value="ใช้ข้อมูลข้างต้น"
                                validate={required}
                              />
                              ใช้ข้อมูลข้างต้น
                            </label>
                          </div>
                          <div className="lg:basis-1/5 md:basis-1/2">
                            <label>
                              <Field
                                name="personType"
                                component="input"
                                type="radio"
                                value="ใช้ข้อมูลอื่น"
                                validate={required}
                              />
                              ใช้ข้อมูลอื่น
                            </label>
                          </div>
                        </div>
                      </div>
                    </BgWhiteForm>

                    <div className="mt-4">
                      <button
                        type="submit"
                        className="bg-blue-500 text-white p-2 rounded"
                      >
                        บันทึก
                      </button>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewCostomerRegister;
