// components/TableComponent.tsx
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import DataTable, { TableStyles } from "react-data-table-component"; // Import TableStyles for typing customStyles
import "./Index.css";
import searchImage from "../../assets/icon/search.png";
import NewCustomerService from "../../services/newCustomerApi";
import { GetNewCustomerFilter } from "../../types/customer";
import { showDatetimeFormatTh, showDatetimeFormat } from "../../utils/date";
import DropdownTable from "../dropdown/DropDownTable";

import ThaiDatePicker from "../datepickercustom/ThaiDatePicker";
import ThaiMonthPicker from "../datepickercustom/ThaiMonthPicker";

interface TableProps {}

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: 16px;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

// Define your custom styles and use TableStyles type
const customStyles: TableStyles = {
  headCells: {
    style: {
      padding: "0px",
      textAlign: "center",
      backgroundColor: "#3758F9",
      color: "#FFF",
      "&:nth-child(1)": {
        padding: "0px",
        textAlign: "end",
        borderRadius: "15px",
      },
      "&:nth-child(8)": {
        padding: "0px",
        textAlign: "center",
        borderRadius: "15px",
      },
    },
  },
  headRow: {
    style: {
      backgroundColor: "#3758F9",
      color: "#FFFFFF",
      fontSize: "18px",
      fontWeight: "bold",
      position: "sticky",
      top: "0", // Stick to the top
      zIndex: 100, // Ensure it stays above other elements
    },
  },
  cells: {
    style: {
      padding: "12px",
      textAlign: "center",
      "&:nth-child(1)": {
        padding: "0px",
        textAlign: "center",
      },
      "&:nth-child(7)": {
        padding: "0px",
        textAlign: "center",
      },
    },
  },
  rows: {
    style: {
      minHeight: "50px", // Row height
      "&:hover": {
        backgroundColor: "#f1f5f9", // Hover color
      },
    },
  },
  pagination: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: "#E2E8F0",
      padding: "10px",
    },
  },
};

const CustomerTable: React.FC<TableProps> = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [defaultMonth, setDefaultMonth] = useState<string>("");
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const options = [
    "แสดงทั้งหมด",
    "เดือนปัจจุบัน",
    "เดือนก่อน",
    "เลือกช่วงวันที่",
  ];
  const [defaultOption, setDefaultOption] = useState(options[0]);
  const columns = [
    {
      name: "ลำดับ",
      selector: (row: any) => row.no,
      cell: (row: any) => (
        <div className="w-[100%] flex justify-center items-center  text-[15px]">
          {row.no}
        </div>
      ),
      sortable: true,
      width: "80px !important",
    },
    {
      name: "วันที่ติดต่อ",
      selector: (row: any) => showDatetimeFormat(new Date(row.created_at)),
      cell: (row: any) => (
        <div className="w-[100%] flex justify-start items-center  text-[15px]">
          {showDatetimeFormatTh(new Date(row.created_at))}
        </div>
      ),
      sortable: true,
      width: "125px !important",
    },
    {
      name: "ชื่อลูกค้า",
      selector: (row: any) =>
        row.addresses.length > 0 ? row.addresses[0].company_name : "",
      cell: (row: any) => (
        <div className="w-[100%] flex justify-start items-center truncate-text text-[15px]">
          {row.addresses.length > 0 ? row.addresses[0].company_name : ""}
        </div>
      ),
      minWidth: "275px !important",
      sortable: true,
    },
    {
      name: "ชื่อสถานพยาบาล",
      selector: (row: any) => row.business_name,
      cell: (row: any) => (
        <div className="w-[100%] flex justify-start items-center truncate-text text-[15px]">
          {row.business_name}
        </div>
      ),
      minWidth: "275px !important",
      sortable: true,
    },
    {
      name: "ชื่อผู้ติดต่อ",
      selector: (row: any) => row.coordinator_full_name,
      cell: (row: any) => (
        <div className="w-[100%] flex justify-start items-center truncate-text text-[15px]">
          {row.coordinator_full_name}
        </div>
      ),
      sortable: true,
      width: "225px !important",
    },
    {
      name: "ตำแหน่ง",
      selector: (row: any) => row.coordinator_position,
      cell: (row: any) => (
        <div className="w-[100%] flex justify-start items-center truncate-text text-[15px]">
          {row.coordinator_position}
        </div>
      ),
      sortable: true,
      width: "225px !important",
    },
    {
      name: "เบอร์โทรศัพท์",
      selector: (row: any) => row.coordinator_phone,
      cell: (row: any) => (
        <div className="w-[100%] flex justify-center items-center truncate-text text-[15px]">
          {row.coordinator_phone}
        </div>
      ),
      width: "175px !important",
      sortable: true,
    },
    {
      name: "",
      cell: (row: any) => (
        <div className="w-[100%] justify-start items-center">
          <DropdownTable rowId={row.id} onDelete={handleDelete} />
        </div>
      ),
      width: "75px !important",
    },
  ];

  const fetchNewCustomer = async (payload: GetNewCustomerFilter) => {
    setLoading(true);
    NewCustomerService.getNewCustomers(payload).then(
      async (res) => {
        if (res.status === 200) {
          let result: any = [];
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            item.no = i + 1 + (res.data.page - 1) * res.data.perPage;
            result.push(item);
          }
          await setPage(res.data.page);
          await setData(result);
          await setPerPage(res.data.perPage);
          await setTotalRows(res.data.total);
        }
        if (res.status === 400) {
        }
        setLoading(false);
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const handleDelete = async (rowId: number) => {
    await NewCustomerService.deleteNewCustomerById(String(rowId)).then(
      async (res) => {
        if (res.status === 200) {
          let payload: GetNewCustomerFilter = {
            perPage: perPage,
            page: page,
            startDate: startDate,
            endDate: endDate,
            search: search,
            isNewCustomer: true,
          };
          await fetchNewCustomer(payload); // fetch page 1 of users
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <Spinner />
      <div className="flex justify-center font-bold">Loader...</div>
    </div>
  );

  const handlePageChange = async (page: any) => {
    await setPage(page);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    await setPerPage(newPerPage);
    await setPage(1);
  };

  const handleDatetime = async (
    startDateLocal: string,
    endDateLocal: string
  ) => {
    let payload: GetNewCustomerFilter = {
      perPage: perPage,
      page: 1,
      startDate: startDateLocal,
      endDate: endDateLocal,
      search: search,
      isNewCustomer: true,
    };
    await fetchNewCustomer(payload);
  };

  const handleInputSearch = async (searchText: string) => {
    await setSearch(searchText);
    let payload: GetNewCustomerFilter = {
      perPage: perPage,
      page: page,
      startDate: startDate,
      endDate: endDate,
      search: searchText,
      isNewCustomer: true,
    };
    await fetchNewCustomer(payload);
  };

  const handleOnSelect = async (e: any) => {
    const currentDate = new Date();
    let startDateLocal: string = "";
    let endDateLocal: string = "";
    setDefaultOption(e.value);
    switch (e.value) {
      case options[1]:
        startDateLocal = showDatetimeFormat(
          new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
        );
        endDateLocal = showDatetimeFormat(
          new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
        );
        break;
      case options[2]:
        return;
      case options[3]:
        return;
    }
    await handleDatetime(startDateLocal, endDateLocal);
  };

  const handleMonthSelect = (month: string) => {
    console.log("Selected month:", month);
  };

  const setSelectDate = (startDateSet: any, endDateSet: any) => {
    console.log(startDateSet, endDateSet);
    let payload: GetNewCustomerFilter = {
      perPage: perPage,
      page: page,
      startDate: startDate,
      endDate: endDate,
      search: search,
      isNewCustomer: true,
    };
    if (startDateSet != null) {
      setStartDate(startDateSet);
      payload.startDate = startDateSet;
    }
    if (endDateSet != null) {
      setEndDate(endDateSet);
      payload.endDate = endDateSet;
    }

    fetchNewCustomer(payload);
  };

  const selectMonth = async (value: string) => {
    console.log(value);
    await setDefaultMonth(value);
    const currentDate = new Date(value + "-01");
    // currentDate.setMonth(currentDate.getMonth() - 1);
    let startDateLocal: string = showDatetimeFormat(
      new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
    );
    let endDateLocal: string = showDatetimeFormat(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
    );
    const formattedMonth = currentDate.toISOString().substring(0, 7); // YYYY-MM
    setDefaultMonth(formattedMonth);
    handleDatetime(startDateLocal, endDateLocal);
  };

  const handleDateSelect = (date: string) => {
    console.log("วันที่ที่เลือก:", date);
  };

  useEffect(() => {
    if (defaultOption === options[2]) {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 1);
      let startDateLocal: string = showDatetimeFormat(
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
      );
      let endDateLocal: string = showDatetimeFormat(
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
      );
      const formattedMonth = currentDate.toISOString().substring(0, 7); // YYYY-MM
      console.log(formattedMonth);
      setDefaultMonth(formattedMonth);
      handleDatetime(startDateLocal, endDateLocal);
    } else if (defaultOption === options[3]) {
      const today = new Date();
      const formattedDate = today.toISOString().substring(0, 10); // YYYY-MM-DD
      setStartDate(formattedDate);
      setEndDate(formattedDate);
      handleDatetime(formattedDate, formattedDate);
    }
  }, [defaultOption]);

  useEffect(() => {
    let payload: GetNewCustomerFilter = {
      perPage: perPage,
      page: page,
      startDate: startDate,
      endDate: endDate,
      search: search,
      isNewCustomer: true,
    };
    fetchNewCustomer(payload); // fetch page 1 of users
  }, [perPage, page]);

  return (
    <div className="body-page">
      <div className="flex justify-between p-2">
        <div className="flex items-center">
          <div>ช่วงเวลา:</div>
          <div className="mx-4">
            <Dropdown
              options={options}
              onChange={handleOnSelect}
              value={defaultOption}
              placeholder="Select an option"
              className="myDropdown"
              controlClassName="myDropdownControl"
              arrowClassName="myDropdownArrow"
              menuClassName="myDropdownMenu"
            />
          </div>
          {defaultOption === options[2] ? (
            <>
              <div className="mx-2">
                {/* <input
                  type="month"
                  className="rounded-xl border-2 px-3 py-[3px] shadow-sm text-[#637381]"
                  value={defaultMonth} // Set the default month
                  onChange={(e) => selectMonth(e.target.value)}
                /> */}
                <ThaiMonthPicker
                  onMonthSelect={selectMonth}
                  value={defaultMonth}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {defaultOption === options[3] ? (
            <>
              <div className="mx-2">
                <ThaiDatePicker
                  onDateSelect={(value) => setSelectDate(value, null)}
                  value={startDate}
                />
              </div>
              <div>
                <ThaiDatePicker
                  onDateSelect={(value) => setSelectDate(null, value)}
                  value={endDate}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div>
          <div className="flex rounded-md">
            <input
              placeholder="ค้นหา"
              className="border-l-2 border-t-2 border-b-2 pl-4 py-1 rounded-l-xl"
              onBlur={(e) => handleInputSearch(e.target.value)}
            />
            <button className="bg-[#3758F9] rounded-r-md w-10 flex justify-center items-center">
              <img src={searchImage} alt="" className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
      <div className="card rounded-2xl shadow-md my-2">
        <div className="px-4">
          <DataTable
            columns={columns}
            data={data}
            customStyles={customStyles} // Apply custom styles here
            pagination
            paginationServer
            paginationPerPage={perPage}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressComponent={<CustomLoader />}
            progressPending={loading}
            noDataComponent={<div className="p-6">ไม่พบข้อมูล</div>}
            persistTableHead
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerTable;
