// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-dropdown-header-table {
  position: fixed !important;
  /* position: absolute; */
  width: 205px;
  margin: 0;
  opacity: 1;
  transition: top 0.3s ease, opacity 0.3s ease;
}
`, "",{"version":3,"sources":["webpack://./src/components/dropdown/DropdownTable.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,wBAAwB;EACxB,YAAY;EACZ,SAAS;EACT,UAAU;EACV,4CAA4C;AAC9C","sourcesContent":[".menu-dropdown-header-table {\n  position: fixed !important;\n  /* position: absolute; */\n  width: 205px;\n  margin: 0;\n  opacity: 1;\n  transition: top 0.3s ease, opacity 0.3s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
