import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NewCustomerRegister from "../pages/NewCustomerRegister";
import Home from "../pages/Home";
import Customer from "../pages/Customer/Index";
import NewCustomer from "../pages/NewCustomer/Index";
import NewCustomerDetail from "../pages/NewCustomer/[id]";
import CreateCustomerDetail from "../pages/NewCustomer/Create"
import LoginPage from "../pages/Login/Index";
import PrivateRoute from "./privateRoute";
import { useDispatch } from "react-redux";
import { getAuth } from "../stores/slices/Auth";
import ForgotPassword from "../pages/ForgotPassowrd/Index";
import MyForm from "../pages/test";

const Web = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAuth());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<NewCustomerRegister />} />
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/test" element={<MyForm />}></Route>
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/customers" element={<Customer />} />
          <Route path="/new-customers" element={<NewCustomer />} />
          <Route path="/create-customer" element={<CreateCustomerDetail />} />
          <Route path="/new-customer/:id" element={<NewCustomerDetail />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Web;
