import axios from "axios";
import { getStorage, setStorage, removeStorage } from "../utils/storage"; // Assume you have these helper functions

const baseUrl = "https://api-newchok.robinsoft.co/";

const apiClient = axios.create({
  baseURL: baseUrl + "api/",
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to refresh the token
const refreshToken = async () => {
  const refresh_token = getStorage("refreshToken");

  if (!refresh_token) {
    throw new Error("No refresh token available");
  }

  try {
    const response = await axios.post(baseUrl + "api/refresh-token", {
      refresh_token: refresh_token,
    });

    // Assuming the response returns new access and refresh tokens
    const newAccessToken = response.data.data.access_token;
    // const newRefreshToken = response.data.data.refresh_token;

    // Store new tokens
    setStorage("token", newAccessToken);
    // setStorage("refreshToken", newRefreshToken);

    return newAccessToken; // Return new access token
  } catch (e) {
    console.error("Error refreshing token:", e);
    removeStorage("token");
    removeStorage("refreshToken");
    removeStorage("userInfo");
    window.location.href = "/login"; // Redirect to login page if refreshing fails
    return Promise.reject(e);
  }
};

// Axios interceptor for handling 401
apiClient.interceptors.response.use(
  (response) => response, // If the response is successful, just return it
  async (error) => {
    const originalRequest = error.config;

    // If the error response status is 401 and the request wasn't already retried
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Prevent infinite retry loop

      try {
        // Attempt to refresh the token
        const newAccessToken = await refreshToken();

        // Update the Authorization header with the new token
        apiClient.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${newAccessToken}`;
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

        // Retry the original request with the new token
        return apiClient(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    // If the error is not a 401 or the retry fails, reject the promise
    return Promise.reject(error);
  }
);

export default apiClient;
